import { put } from 'redux-saga/effects';

import { setIsLoading } from '../actions/common';
import { API } from '../../api';
import { showErrorToast } from '../../components/toastWrapper';
import { removeDeviceFormStore, setItem, setList } from '../actions/devices';
import { history } from '../../store';
import { routes } from '../../routes';

export function* loadDeviceListSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.devices.list(action.payload);
    yield put(setList({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* loadDeviceItemSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.devices.item(action.payload);
    yield put(setItem({ ...mockItemData(json) }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* deleteDeviceItemSaga(action) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.devices.remove(action.payload);
    yield put(removeDeviceFormStore(action.payload));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* createDeviceSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.devices.create(payload);
    yield put(setIsLoading(false));
    history.push(routes.devices());
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

const mockItemData = () => ({
  id: '391b8411-031a-4f80-8be7-fbb689523ccc',
});
