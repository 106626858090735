/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactNode } from 'react';

import cn from 'classnames';

import {
  Button,
  Spinner,
  Stepper,
  TabsComponent,
  Icon,
} from '../../../src/index';

import css from './index.module.scss';
import { useTranslation } from 'react-i18next';

export type Step = {
  disableBack?: boolean;
  disabled: boolean;
  label: string;
  step: number;
};

type FormWrapperType = {
  children: ReactNode;
  headerActions?: ReactNode[];
  title: string;
  subTitle?: string;
  showTitle?: boolean;
  submitLabel: string;
  cancelLabel: string;
  draftLabel: string;
  onCancel: () => void;
  showLoader?: boolean;
  onSubmit: () => void;
  disableSubmit?: boolean;
  disableDraft?: boolean;
  tabs?: any;
  steps?: Step[];
  onChangeTab?: (v: number) => void;
  isSubmitLoading?: boolean;
  currentStep?: number;
  setStep?: (v: Step) => void;
  onSaveDraft?: () => void;
  hasDraft?: boolean;
  isLoading: boolean;
  onClickBack?: () => void;
};

export const FormWrapper: FC<FormWrapperType> = ({
  children,
  title,
  subTitle,
  showTitle = true,
  submitLabel,
  cancelLabel,
  draftLabel,
  onCancel,
  showLoader = true,
  onSubmit,
  disableSubmit = false,
  disableDraft = true,
  tabs,
  steps,
  onChangeTab = () => {},
  isSubmitLoading = false,
  currentStep = 0,
  setStep = () => {},
  onSaveDraft = () => {},
  hasDraft = false,
  onClickBack = () => {},
  isLoading,
  headerActions,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {tabs && tabs.length > 0 && (
        <div className={css['tabsWrapper']}>
          <TabsComponent
            items={tabs}
            variant="standard"
            onChangeTab={onChangeTab}
          />
        </div>
      )}
      <div
        className={cn(
          [css['viewWrapper']],
          { [css['viewWrapper_full']]: !tabs || tabs.length === 0 },
          { [css['viewWrapper_withSteps']]: steps && steps.length > 0 }
        )}
      >
        <div>
          {((!isLoading && showTitle) || !showLoader) && (
            <div className={css['viewWrapper__header']}>
              <div className={css['viewWrapper__title']}>
                <button
                  className={css['backArrowButton']}
                  type="button"
                  onClick={onClickBack}
                >
                  <Icon name="Back" />
                </button>
                <div>
                  <p className={css.titleFont}>{title}</p>
                  {subTitle && <p className={css.subTitleFont}>{subTitle}</p>}
                </div>
              </div>
              <div className={css['viewWrapper__headerActions']}>
                {headerActions && headerActions}
              </div>
            </div>
          )}
          <div
            className={
              isLoading && showLoader
                ? css['wrapper__disabled']
                : css['wrapper__main']
            }
          >
            <div className={css['wrapper__content']}>{children}</div>
            {steps && steps.length > 0 && (
              <div className={css['wrapper__stepper']}>
                <Stepper
                  currentStep={currentStep}
                  steps={steps}
                  onStepClick={setStep}
                />
              </div>
            )}
          </div>
        </div>
        {isLoading && showLoader && (
          <div className={css['viewWrapper__spinner']}>
            <Spinner />
          </div>
        )}
        <div>
          {/*<div className={css['divider']} />*/}
          <div
            className={cn(css['viewWrapper__footer'], css['buttonStylesSM'])}
          >
            <div>
              {hasDraft && (
                <Button
                  disabled={disableDraft || isSubmitLoading}
                  size="large"
                  type="button"
                  variant="text"
                  onClick={onSaveDraft}
                >
                  {draftLabel || t('Сохранить черновик')}
                </Button>
              )}
            </div>
            <div className={css.cancelSubmitBtns}>
              <Button
                color="primary"
                size="large"
                type="button"
                variant="text"
                onClick={(e) => {
                  onCancel();
                  e.currentTarget.blur();
                }}
              >
                {cancelLabel || t('Отмена')}
              </Button>
              <Button
                color="primary"
                disabled={disableSubmit || isSubmitLoading}
                size="large"
                type="submit"
                onClick={onSubmit}
              >
                {submitLabel || t('Сохранить')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
