import React, { memo } from 'react';

import cn from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import { FormControl, FormHelperText } from '@mui/material';

import {
  AutocompleteAsync,
  type AutocompleteAsyncProps,
} from '../../mui/autocomplete-async';
import { PartialKeys } from '../../utils/types.utils';
import { useEvent } from '../../utils/react.hooks';

import css from './autocomplete-async-field.module.scss';

export type AutocompleteAsyncFieldValue<T = string> = {
  value: T;
  label: string;
};

export type AutocompleteAsyncFieldProps<
  T = string,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> = PartialKeys<
  AutocompleteAsyncProps<
    AutocompleteAsyncFieldValue<T>,
    Multiple,
    DisableClearable,
    FreeSolo
  >,
  'options'
> &
  FieldRenderProps<
    AutocompleteAsyncProps<
      AutocompleteAsyncFieldValue<T>,
      Multiple,
      DisableClearable,
      FreeSolo
    >['value'],
    HTMLElement
  >;

const Component = <
  T = string,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>(
  props: AutocompleteAsyncFieldProps<T, Multiple, DisableClearable, FreeSolo>
) => {
  const {
    className,
    disabled,
    fullWidth,
    input: {
      name,
      value: valueField,
      multiple,
      onBlur: onBlurField,
      onChange: onChangeField,
      onFocus: onFocusField,
    },
    meta,
    required,
    multiple: _multiple,
    options = [],
    size = 'small',
    onChange,
    onBlur,
    onFocus,
    ...autocompleteProps
  } = props;

  type Props = Required<
    AutocompleteAsyncProps<
      AutocompleteAsyncFieldValue<T>,
      Multiple,
      DisableClearable,
      FreeSolo
    >
  >;

  const isVisibleError = !disabled && (meta.touched || meta.modified);
  const hasError = isVisibleError && Boolean(meta.error);
  const hasValue = Array.isArray(valueField)
    ? !!valueField?.length
    : valueField !== '' && valueField !== null && valueField !== undefined;
  const helperText = isVisibleError ? meta.error : undefined;
  const cnAutocomplete = cn(
    ((!hasValue && required) || hasError) && css.fieldRequiredError
  );

  const handleBlur = useEvent<Props['onBlur']>((event, ...args) => {
    onBlurField(event);
    onBlur?.(event, ...args);
  });

  const handleChange = useEvent<Props['onChange']>(
    (event, value, reason, details) => {
      onChangeField(value);
      onChange?.(event, value, reason, details);
    }
  );

  const handleFocus = useEvent<Props['onFocus']>((event, ...args) => {
    onFocusField(event);
    onFocus?.(event, ...args);
  });

  return (
    <FormControl className={cn(css.container, className)} fullWidth={fullWidth}>
      <AutocompleteAsync<
        AutocompleteAsyncFieldValue<T>,
        Multiple,
        DisableClearable,
        FreeSolo
      >
        {...autocompleteProps}
        className={cnAutocomplete}
        disabled={disabled}
        error={hasError}
        fullWidth={fullWidth}
        multiple={multiple as Multiple}
        name={name}
        options={options}
        required={required}
        size={size}
        value={valueField}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
      />
      {helperText && (
        <FormHelperText
          disabled={disabled}
          error={hasError}
          required={required}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export const AutocompleteAsyncField = memo(Component) as typeof Component;
