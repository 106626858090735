import React from 'react';

import { FieldRenderProps } from 'react-final-form';
import cn from 'classnames';

import { InputTags, InputTagsProps } from '../../application/input-tags';

import css from './input-tags.module.scss';

/**
 * @deprecated
 * use `<SelectboxField multiply />` or `<AutocompleteField multiply />` instead
 * */
export type InputTagsFieldProps = InputTagsProps &
  FieldRenderProps<string[] | undefined>;

/**
 * @deprecated
 * use `<SelectboxField multiply />` or `<AutocompleteField multiply />` instead
 * */
export const InputTagsField = React.memo<InputTagsFieldProps>(
  ({ ...props }) => {
    const {
      disabled,
      fullWidth,
      input: { name, onChange, value, ...restInput },
      label,
      meta,
      required,
    } = props;

    const isVisibleError = !disabled && (meta.touched || meta.modified);
    const hasError = isVisibleError && Boolean(meta.error);

    return (
      <InputTags
        InputProps={restInput}
        className={cn({
          [css.fieldRequiredError]: (!value?.length && required) || hasError,
          [css.disabled]: disabled,
          [css.hasError]: hasError,
        })}
        disabled={disabled}
        error={hasError}
        fullWidth={fullWidth}
        helperText={isVisibleError && meta.error}
        label={label}
        name={name}
        required={required}
        value={value}
        onChange={onChange}
      />
    );
  }
);
