import { types } from '../types';
import { createAction } from '../../helpers/redux';

export const login = createAction(types.LOGIN);
export const logout = createAction(types.LOGOUT);
export const setAuthData = createAction(types.SET_AUTH_DATA);
export const clearAuthData = createAction(types.CLEAR_AUTH_DATA);
export const check = createAction(types.CHECK_AUTH_DATA);
export const setUser = createAction(types.SET_CURRENT_USER);
export const clearUser = createAction(types.CLEAR_CURRENT_USER);
export const checkExpDate = createAction(types.CHECK_EXP_DATE);
