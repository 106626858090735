import React from 'react';

import type {} from '@mui/material/themeCssVarsAugmentation';

import {
  createTheme,
  StyledEngineProvider,
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
  type Theme as MUITheme,
  type Palette as MUIPalette,
} from '@mui/material/styles';
import * as locales from '@mui/material/locale';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// import { theme as figmaTheme } from './tokens/tokens2theme';
import figmaTheme from './figma-theme/figma-theme.json';
import { overrideTheme } from './figma-theme/override-theme';
import { hyPalette } from './palettes/hy-pallete';

export type IBoxPalette = MUIPalette;
export type IBoxTheme = MUITheme & { palette: IBoxPalette };

type CountryCode = 'AZ' | 'KZ' | 'UZ' | 'HY' | 'AM';
type MUILocales = typeof locales;
type MUILocaleName = keyof typeof locales;
type AppLocale = 'en' | 'ru' | 'az' | 'kz' | 'uz' | 'hy' | 'am';
type MUILocaleSupported = Extract<
  MUILocaleName,
  'enUS' | 'ruRU' | 'azAZ' | 'kkKZ' | 'hyAM'
>;

const MUI_LOCALE_NAMES: Record<AppLocale, MUILocaleSupported> = {
  en: 'enUS',
  ru: 'ruRU',
  az: 'azAZ',
  kz: 'kkKZ',
  uz: 'ruRU',
  hy: 'hyAM',
  am: 'hyAM',
};

const getPalette = (countryCode: CountryCode) => {
  switch (countryCode) {
    case 'AM':
      return hyPalette;
    case 'HY':
      return hyPalette;
    default:
      return figmaTheme;
  }
};

export const getLocalizedTheme = (
  countryCode: CountryCode,
  appLocaleName: AppLocale
): MUITheme => {
  const muiLocaleName = MUI_LOCALE_NAMES[appLocaleName] ?? '';
  const locale = (locales as MUILocales)[muiLocaleName];
  const palette = getPalette(countryCode);
  const theme = createTheme(palette, overrideTheme, locale);
  return theme;
};

type ThemeProps = {
  theme: MUITheme;
  children: React.ReactNode;
};

export const Theme = React.memo<ThemeProps>(({ theme, children }) => {
  const extendedTheme = extendTheme(theme);
  return (
    <StyledEngineProvider injectFirst={true}>
      <CssVarsProvider theme={extendedTheme}>{children}</CssVarsProvider>
    </StyledEngineProvider>
  );
});
