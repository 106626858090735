import { format } from 'date-fns';
import { isNil } from 'lodash/fp';

import { Types, types } from '../types';
import { PRODUCT_GROUPS_NAMES } from '../../constants';
import {
  MARKING_OPTIONS_NAMES,
  MARKING_STATUSES,
  ORDER_TYPE_NAMES,
} from '../../pages/marking/constants';
import { ProductGroupTypes } from '../../pages/reports/constants';

type ReportStatusTypes =
  | 'DRAFT'
  | 'READY_TO_SEND'
  | 'PENDING'
  | 'SENT'
  | 'REJECTED';

type DataType = {
  brandcode: string;
  expirationDate: string;
  isAutoReport: string;
  orderType: string;
  productionDate: string;
  productionLineId: string;
  productionOrderId: string;
  seriesNumber: string;
  sntins: string[];
  sourceReportId: string;
  usageType: string;
  usedInProduction: string;
  count?: number;
  pg?: ProductGroupTypes;
};

type MarkingDataType = {
  createdTimestamp: string;
  isAutoReport: boolean;
  json: DataType;
  original: DataType;
  pg: ProductGroupTypes;
  pgName: string;
  products: { code: string }[];
  reportId: string;
  status: ReportStatusTypes;
  statusName: string;
  type: string;
  updatedTimestamp: string;
};

export type MarkingState = {
  item: MarkingDataType | null;
  isError: boolean;
};

const initialState: MarkingState = {
  item: null,
  isError: false,
};

type MarkingActionType = Types[keyof Pick<
  Types,
  | 'MARKING_SET_ITEM'
  | 'CLEAR_MARKING'
  | 'MARKING_SET_ITEM_ERROR'
  | 'SET_MARKING'
>];

type MarkingAction = {
  type: MarkingActionType;
  payload: unknown;
};

export const reducer = (state = initialState, action: MarkingAction) => {
  switch (action.type) {
    case types.MARKING_SET_ITEM:
      return {
        ...state,
        item: parseItem(action.payload),
      };
    case types.CLEAR_MARKING:
      return {
        ...state,
        item: {},
      };
    case types.MARKING_SET_ITEM_ERROR:
      return {
        ...state,
        isError: action.payload,
      };
    case types.SET_MARKING:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const parseItem = (data: unknown): MarkingDataType => ({
  ...data,
  pgName: PRODUCT_GROUPS_NAMES[data.pg as keyof typeof PRODUCT_GROUPS_NAMES],
  statusName: MARKING_STATUSES[data.status as keyof typeof MARKING_STATUSES],
  products: data.json?.utilisationReport?.sntins
    ? data.json.utilisationReport.sntins.map((item: string) => ({ code: item }))
    : data.json?.sntins?.map((item: string) => ({ code: item })),
  createdTimestamp: format(new Date(data.createdTimestamp), 'dd.MM.yyyy HH:mm'),
  json: {
    productionLineId: data.json.productionLineId,
    sntins: data.json.sntins,
    usedInProduction: data.json.usedInProduction
      ? 'Да'
      : isNil(data.json.usedInProduction)
      ? '—'
      : 'Нет',
    usageType:
      MARKING_OPTIONS_NAMES[
        data?.json?.usageType as keyof typeof MARKING_OPTIONS_NAMES
      ],
    orderType:
      ORDER_TYPE_NAMES[data?.json?.orderType as keyof typeof ORDER_TYPE_NAMES],
    productionDate: data.json.productionDate
      ? format(new Date(data.json.productionDate), 'dd.MM.yyyy')
      : '—',
    expirationDate: data.json.expirationDate
      ? format(new Date(data.json.expirationDate), 'dd.MM.yyyy')
      : '—',
    productionOrderId: data.json.productionOrderId,
    brandcode: data.json.brandcode,
    seriesNumber: data.json.seriesNumber ? data.json.seriesNumber : '—',
    sourceReportId: data.json.sourceReportId,
    isAutoReport: data.isAutoReport ? 'Автоматически' : 'Пользователем',
  },
  original: {
    pg: data.pg,
    sntins: data.json.sntins,
    productionLineId: data.json.productionLineId,
    productionOrderId: data.json.productionOrderId,
    brandcode: data.json.brandcode,
    seriesNumber: data.json.seriesNumber,
    sourceReportId: data.json.sourceReportId,
    usageType: data?.json?.usageType,
    productionDate: data.json.productionDate
      ? new Date(data.json.productionDate)
      : '',
    expirationDate: data.json.expirationDate
      ? new Date(data.json.expirationDate)
      : '',
    count:
      data.json?.sntins?.length || data.json?.utilisationReport?.sntins?.length,
  },
});
