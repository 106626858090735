import React from 'react';

import { Box, BoxProps } from '@mui/material';

import styles from './common-styles.module.scss';

type MenuSpaceProps = Omit<BoxProps, 'children'>;

export function MainMenuSpace(props: MenuSpaceProps) {
  return <Box {...props} className={styles.AppMainMenuSpace} />;
}
