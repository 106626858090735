import React, { FC } from 'react';

import cn from 'classnames';
import {
  Dialog,
  IconButton,
  Typography,
  DialogContent,
  DialogActions,
  DialogTitle as MuiDialogTitle,
  DialogContentText,
  Breakpoint,
} from '@mui/material';

import { Icon } from '../icons';
import { Button } from '../../mui/button';

import css from './dialog.module.scss';

type DialogTitlePropsType = {
  onClose: () => void;
  children: React.ReactNode;
  isLoading?: boolean;
};

export type CommonDialogPropsType = {
  labelSubmit: string;
  labelCancel: string;
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  children?: React.ReactNode;
  text?: React.ReactNode;
  title: string;
  className?: string;
  size: Breakpoint;
  errorSubmit?: boolean;
  invalid?: boolean;
  isLoading?: boolean;
};

const DialogTitle = (props: DialogTitlePropsType) => {
  const { children, onClose, isLoading = false, ...other } = props;

  const onCloseModal = () => {
    if (!isLoading) {
      onClose();
    }
  };

  return (
    <MuiDialogTitle className={css['commonDialog__container']} {...other}>
      <Typography
        className={css['commonDialog__text']}
        component="div"
        variant="h3"
      >
        {children}
      </Typography>
      <IconButton aria-label="close" onClick={onCloseModal}>
        <Icon name="Close" />
      </IconButton>
    </MuiDialogTitle>
  );
};

export const CommonDialog: FC<CommonDialogPropsType> = ({
  labelSubmit = 'Да',
  labelCancel = 'Нет',
  onClose,
  onSubmit,
  open,
  children,
  text,
  title,
  className,
  size = 'sm',
  errorSubmit = false,
  isLoading = false,
  ...props
}) => {
  return (
    <Dialog
      fullWidth
      className={cn(css['commonDialog'], className)}
      data-testid="CommonDialog"
      maxWidth={size}
      open={open}
      onClose={onClose}
      {...props}
    >
      {title && (
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          <Typography className={css['commonDialog__title']} variant="h5">
            {title}
          </Typography>
          {text && <DialogContentText>{text}</DialogContentText>}
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {[
          labelCancel && (
            <Button
              color="primary"
              disabled={isLoading}
              key="b-cancel"
              variant="text"
              onClick={onClose}
            >
              {labelCancel}
            </Button>
          ),
          labelSubmit && (
            <Button
              autoFocus
              color={errorSubmit ? 'error' : 'primary'}
              disabled={props.invalid || isLoading}
              key="b-submit"
              type="submit"
              variant="contained"
              onClick={onSubmit}
            >
              {labelSubmit}
            </Button>
          ),
        ]}
      </DialogActions>
    </Dialog>
  );
};
