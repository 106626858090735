import React, { FC } from 'react';

import { Box, Paper, Typography } from '@mui/material';
import cn from 'classnames';

import styles from './info-notification.module.scss';

export type InfoNotificationProps = {
  title?: string;
  children?: React.ReactNode;
  icon: React.ReactNode;
  type: 'error' | 'info';
};

export const InfoNotification: FC<InfoNotificationProps> = ({
  title = '',
  children,
  type = 'info',
  icon,
}) => {
  return (
    <Box
      className={cn(styles.infoNotification, {
        [styles.error]: type === 'error',
      })}
      data-testid="InfoNotification"
    >
      <Paper variant="outlined">
        <Box className={styles.infoNotification__content}>
          <Box className={styles.infoNotification__icon}>{icon}</Box>
          <Box className={styles.infoNotification__text}>
            {title && (
              <Typography className={styles.title} variant="h4">
                {title}
              </Typography>
            )}
            <Typography variant="subtitle1">{children}</Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
