import { Types, types } from '../types';
import { PRODUCT_GROUPS_NAMES } from '../../constants';

import { PAGINATION_DEFAULT } from './constants';

import { PageState } from '.';

type ProductGroupsType = keyof typeof PRODUCT_GROUPS_NAMES;

type ServiceType = {
  productGroups: string[];
  providerCountry: string;
  providerId: string;
  providerName: string;
  providerRole: string;
  providerTin: string;
  serviceId: string;
  serviceName: string;
};

type ServiceProvidesData = {
  serviceId: string;
  providerName: string;
  providerRole: string[];
  providerCountry: string;
  productGroups: ProductGroupsType[];
};

export type CreationListItemType = {
  providerCountry: string;
  providerId: string;
  providerName: string;
  providerRole: string;
  providerTin: number;
  serviceId: string;
  serviceName: string;
};

export type ServiceProvideState = PageState<ServiceProvidesData> & {
  creationList: CreationListItemType[];
};

type ServiceProvideActionType = Types[keyof Pick<
  Types,
  | 'SP_SET_LIST'
  | 'SP_SET_LIST_FOR_CONNECTION'
  | 'SET_SP'
  | 'CLEAR_SP_CONNECTIONS'
>];

type ServiceProvideAction = {
  type: ServiceProvideActionType;
  payload: any;
};

const initialState: ServiceProvideState = {
  list: [],
  creationList: [],
  item: {},
  isLastPage: false,
  filter: {},
  pagination: PAGINATION_DEFAULT,
};

export const reducer = (state = initialState, action: ServiceProvideAction) => {
  switch (action.type) {
    case types.SP_SET_LIST:
      return {
        ...state,
        list: parseServices(action.payload),
      };
    case types.SP_SET_LIST_FOR_CONNECTION:
      return {
        ...state,
        creationList: action.payload?.services || [],
      };
    case types.SET_SP:
      return {
        ...state,
        ...action.payload,
      };
    case types.CLEAR_SP_CONNECTIONS:
      return {
        ...state,
        item: {},
        creationList: [],
      };
    default:
      return state;
  }
};

const parseServices = (data: {
  participantId: number;
  services: ServiceType[];
}) => {
  const list = data?.services || [];
  return list.map((item) => ({
    ...item,
    pgNames: item.productGroups.map(
      (pg) => PRODUCT_GROUPS_NAMES[pg as keyof typeof PRODUCT_GROUPS_NAMES]
    ),
  }));
};
