import { createAction } from '../../helpers/redux';
import { types } from '../types';

export const set = createAction(types.SET_SP);
export const getList = createAction(types.SP_GET_LIST);
export const setList = createAction(types.SP_SET_LIST);
export const getListForConnection = createAction(
  types.SP_GET_LIST_FOR_CONNECTION
);
export const setListForConnection = createAction(
  types.SP_SET_LIST_FOR_CONNECTION
);
export const deleteConnections = createAction(types.DELETE_SP_CONNECTIONS);
export const clearConnections = createAction(types.CLEAR_SP_CONNECTIONS);
export const createConnections = createAction(types.CREATE_SP_CONNECTIONS);
