import { Types, types } from '../types';

export type AuthState = {
  isNotAuth?: boolean;
  access_token?: string;
};

const initialState: AuthState = {};

type AuthActionType = Types[keyof Pick<
  Types,
  'SET_AUTH_DATA' | 'CLEAR_AUTH_DATA'
>];

type AuthAction = {
  type: AuthActionType;
  payload: any;
};

export const reducer = (state = initialState, action: AuthAction) => {
  switch (action.type) {
    case types.SET_AUTH_DATA:
      return {
        ...action.payload,
      };
    case types.CLEAR_AUTH_DATA:
      return { isNotAuth: true };
    default:
      return state;
  }
};
