import React, { FC } from 'react';

import {
  FormControlLabel,
  Radio as MUIRadio,
  FormControlLabelProps as MuiFormControlLabelProps,
  RadioProps as MuiRadioProps,
} from '@mui/material';

import styles from './index.module.scss';

export type RadioProps = Omit<MuiFormControlLabelProps, 'color' | 'control'> &
  Pick<MuiRadioProps, 'disableRipple' | 'disableTouchRipple' | 'color'>;

export const Radio: FC<RadioProps> = ({
  color = 'primary',
  disableRipple = true,
  disableTouchRipple = true,
  ...rest
}) => (
  <FormControlLabel
    control={
      <MUIRadio
        className={styles.container}
        color={color}
        disableRipple={disableRipple}
        disableTouchRipple={disableTouchRipple}
      />
    }
    {...rest}
  />
);
