import { type Theme as MUITheme } from '@mui/material/styles';

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export const overrideTheme: DeepPartial<MUITheme> = {
  palette: {
    AppBar: {
      defaultBg: 'var(--mui-palette-grey-800)',
    },
  },
  typography: {
    h1: {
      fontSize: '2.25rem',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '2.62575rem',
      letterSpacing: '-0.09375rem',
    },
    h2: {
      fontSize: '2rem',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '2.4rem',
      letterSpacing: '-0.03125rem',
    },
    h3: {
      fontSize: '1.75rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '2.04225rem',
    },
    h4: {
      fontSize: '1.5rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.8525rem',
      letterSpacing: '0.01563rem',
    },
    h5: {
      fontSize: '1.5rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '2.001rem',
    },
    h6: {
      fontSize: '1.25rem',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '2rem',
      letterSpacing: '0.00938rem',
    },
    subtitle1: {
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.75rem',
      letterSpacing: '0.00938rem',
    },

    subtitle2: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '1.37375rem',
      letterSpacing: '0.00625rem',
    },
    body1: {
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.5rem',
      letterSpacing: '0.00938rem',
    },
    body2: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.25125rem',
      letterSpacing: '0.01063rem',
    },
    caption: {
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.245rem',
      letterSpacing: '0.025rem',
    },
    overline: {
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.995rem',
      letterSpacing: '0.0625rem',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { size: 'small' },
          style: {
            fontFamily: 'Roboto',
            fontSize: '0.8125rem',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '1.375rem',
            letterSpacing: '0.02875rem',
            textTransform: 'uppercase',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            fontFamily: 'Roboto',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '1.5rem',
            letterSpacing: '0.025rem',
            textTransform: 'uppercase',
          },
        },
        {
          props: { size: 'large' },
          style: {
            fontFamily: 'Roboto',
            fontSize: '0.9375rem',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '1.625rem',
            letterSpacing: '0.02875rem',
            textTransform: 'uppercase',
          },
        },
      ],
    },
  },
};
