import React, { FC } from 'react';

import cn from 'classnames';
import { FieldRenderProps } from 'react-final-form';

import { Checkbox, type CheckboxProps } from '../../mui/checkbox';
import { useEvent } from '../../utils/react.hooks';

import styles from './checkbox-field.module.scss';

export type CheckboxFieldProps = CheckboxProps & FieldRenderProps<boolean>;

export const CheckboxField: FC<CheckboxFieldProps> = (props) => {
  const {
    disabled,
    input: {
      onBlur: onBlurField,
      onChange: onChangeField,
      onFocus: onFocusField,
      ...restInput
    },
    label,
    meta,
    required,
    className,
    onChange,
    onBlur,
    onFocus,
  } = props;

  type Props = Required<CheckboxProps>;

  const isVisibleError = !disabled && (meta.touched || meta.modified);
  const hasError = isVisibleError && Boolean(meta.error);

  const handleBlur = useEvent<Props['onBlur']>((event) => {
    onBlurField(event);
    onBlur?.(event);
  });

  const handleChange = useEvent<Props['onChange']>((event, value) => {
    onChangeField(value);
    onChange?.(event, value);
  });

  const handleFocus = useEvent<Props['onFocus']>((event) => {
    onFocusField(event);
    onFocus?.(event);
  });

  return (
    <Checkbox
      {...restInput}
      className={cn(className, {
        [styles.error]: (!meta.touched && required) || hasError,
      })}
      disabled={disabled}
      label={label}
      required={required}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
    />
  );
};
