import React, { useState, useEffect } from 'react';

import { useEvent } from '../../utils/react.hooks';
import { Button } from '../../mui/button';

type PaginationTableProps = {
  currentPage: number;
  label: string;
  isLoading?: boolean;
  onPageChange: (page: number, offset?: number) => void;
};
export const PaginationTable: React.FunctionComponent<PaginationTableProps> = ({
  currentPage,
  onPageChange,
  label,
  isLoading,
}) => {
  const [page, setPage] = useState(0);
  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const changePage = useEvent((page: number) => {
    setPage(page);
    onPageChange(page, 10 * page);
  });

  const onChangePage = useEvent(() => changePage(page + 1));

  return (
    <Button disabled={isLoading} variant="text" onClick={onChangePage}>
      {label}
    </Button>
  );
};
