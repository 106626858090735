import React, { useCallback, useMemo } from 'react';

import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Link } from '@mui/material';

import styles from './index.module.scss';

export type ServerErrorPageProps = {
  accessDeniedMessage?: string;
  notFoundMessage?: string;
  serverErrorMessage?: string;
  accessDeniedComment?: string;
  serverErrorComment?: string;
  reloadText?: string;
  linkText: string;
  redirectLink: string;
  defaultErrorCode?: string;
};

export const ServerErrorPage: React.FC<ServerErrorPageProps> = ({
  accessDeniedMessage = 'Доступ запрещен',
  notFoundMessage = 'Запрашиваемый объект не найден',
  serverErrorMessage = 'Технические неполадки',
  accessDeniedComment = 'Недостаточно прав для выполнения операции. Обратитесь к своему администратору.',
  serverErrorComment = 'Системная ошибка. Попробуйте обновить страницу.Если проблема сохраняется, обратитесь в службу поддержки.',
  reloadText = 'Обновить страницу',
  linkText = 'Перейти на главную',
  redirectLink,
  defaultErrorCode = '500',
}) => {
  const location = useLocation();
  const history = useHistory();

  const code = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return query.get('code') || defaultErrorCode;
  }, [location, defaultErrorCode]);

  const isServerError = useMemo(() => code[0] === '5', [code]);

  const { message, comment } = useMemo(() => {
    let message = '';
    let comment = '';
    switch (code) {
      case '403':
        message = accessDeniedMessage;
        comment = accessDeniedComment;
        break;
      case '404':
        message = notFoundMessage;
        break;
      default:
        if (isServerError) {
          message = serverErrorMessage;
          comment = serverErrorComment;
        }
    }
    return { message, comment };
  }, [
    accessDeniedComment,
    accessDeniedMessage,
    code,
    isServerError,
    notFoundMessage,
    serverErrorComment,
    serverErrorMessage,
  ]);

  const goBack = useCallback(() => history.goBack(), [history]);

  return (
    <div className={styles.errorWrapper}>
      <div className={styles.line} />
      <div className={styles.stroke}>
        <h2>{code}</h2>
        <h3>{message}</h3>
      </div>
      {comment && <p>{comment}</p>}
      {isServerError ? (
        <button className={styles.transfer} type="button" onClick={goBack}>
          {reloadText}
        </button>
      ) : (
        <Link
          className={styles.transfer}
          component={RouterLink}
          to={redirectLink}
        >
          {linkText}
        </Link>
      )}
    </div>
  );
};
