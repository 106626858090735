import { format } from 'date-fns';

import { getToken, getParamFromToken } from '../../utils/utils';
import { ProductGroupTypes } from '../reports/constants';

export const getSntinsLength = (
  data: { sntins: string[] },
  type: 'sntin' | 'sntinAndSerial' | 'sntinAndImei'
): { sntinsLength: number; additional: null | number } => {
  switch (type) {
    case 'sntin':
      return { sntinsLength: data?.sntins?.length, additional: null };
    case 'sntinAndSerial':
    case 'sntinAndImei': {
      const keys = Object.keys(data?.sntins) || [];
      const values = Object.values(data?.sntins) || [];
      const notEmptyValues = values.filter((item: string[] | string) => {
        if (typeof item === 'string') {
          return !!item;
        }
        return item?.length > 0;
      });
      return { sntinsLength: keys?.length, additional: notEmptyValues.length };
    }
    default:
      return { sntinsLength: data?.sntins?.length, additional: null };
  }
};

export const prepareBody = (values: {
  pg: ProductGroupTypes;
  releaseMethodType: 'IMPORT' | 'PRODUCTION' | 'EAEU';
  productionDate: Date;
  expirationDate: Date;
  declarationNumber: number;
  primaryDocNumber: number;
  seriesNumber: string;
  transportCodes: string[];
  identificationType: string;
  codes: string[];
}) => {
  const accessToken = getToken();
  const participantId = getParamFromToken('inn', { access_token: accessToken });

  const result = {
    pg: values?.pg,
    participantId,
    releaseMethodType: values?.releaseMethodType,
    ...(values?.productionDate && {
      productionDate: format(
        new Date(values.productionDate),
        "yyyy-MM-dd'T'HH:mm:ssxxx"
      ),
    }),
    ...(values?.expirationDate && {
      expirationDate: format(
        new Date(values.expirationDate),
        "yyyy-MM-dd'T'HH:mm:ssxxx"
      ),
    }),
    ...(values?.declarationNumber &&
      values?.releaseMethodType === 'IMPORT' && {
        declarationNumber: values.declarationNumber,
      }),
    ...(values?.primaryDocNumber &&
      values?.releaseMethodType === 'EAEU' && {
        primaryDocNumber: values.primaryDocNumber,
      }),
    ...(values?.seriesNumber && { seriesNumber: values.seriesNumber }),
    ...(values?.codes && {
      identifications: {
        type: values?.identificationType,
        sntins: values?.codes || [],
      },
    }),
  };

  if (values?.transportCodes?.length) {
    result.transportCodes = values.transportCodes;
  }
  return result;
};
