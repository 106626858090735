import fp from 'lodash/fp';
import { format } from 'date-fns';

import { Types, types } from '../types';
import { PRODUCT_GROUPS_NAMES } from '../../constants';
import { MARKING_STATUSES } from '../../pages/marking/constants';
import { DROPOUT_REASON_NAMES } from '../../pages/dropout/constants';
import i18n from '../../i18n';
import { ProductGroupTypes } from '../../pages/reports/constants';

type ReportStatusTypes =
  | 'DRAFT'
  | 'READY_TO_SEND'
  | 'PENDING'
  | 'SENT'
  | 'REJECTED';

type DataType = {
  address: string;
  dropoutReason: string;
  isSpdn: boolean;
  participantId: string;
  productLineId: null | string;
  productOrderId: string;
  sntins: string[];
  sourceDocDate: string;
  sourceDocNum: string;
  spdnAreaId: null | string;
  spdnReportId: null | string;
  spdnUuid: null | string;
  withChild: boolean;
  count?: number;
  pg?: ProductGroupTypes;
  productionLineId?: null | string;
  productionOrderId?: string;
};

type RejectionData = {
  address: string;
  createdTimestamp: string;
  dropoutReason: string;
  dropoutReasonName: string;
  json: DataType;
  original: DataType;
  participantId: string;
  pg: ProductGroupTypes;
  pgName: string;
  productLineId: string;
  productOrderId: string;
  products: { code: string }[];
  rejectReason: undefined | string;
  reportId: string;
  sourceDocDate: string;
  sourceDocNum: string;
  status: ReportStatusTypes;
  statusName: string;
  type: string;
  updatedTimestamp: string;
  withChild: string;
};

export type RejectionState = {
  item: RejectionData | null;
};

const initialState: RejectionState = {
  item: null,
};

type RejectionActionType = Types[keyof Pick<
  Types,
  'REJECTION_SET_ITEM' | 'SET_REJECTION' | 'CLEAR_REJECTION'
>];

type RejectionAction = {
  type: RejectionActionType;
  payload: unknown;
};

export const reducer = (state = initialState, action: RejectionAction) => {
  switch (action.type) {
    case types.REJECTION_SET_ITEM:
      return {
        ...state,
        item: parseItem(action.payload),
      };
    case types.SET_REJECTION:
      return {
        ...state,
        ...action.payload,
      };
    case types.CLEAR_REJECTION:
      return {
        ...state,
        item: {},
      };
    default:
      return state;
  }
};

const parseItem = (data: unknown) => ({
  ...data,
  pgName: i18n.t(
    PRODUCT_GROUPS_NAMES[data.pg as keyof typeof PRODUCT_GROUPS_NAMES]
  ),
  statusName: i18n.t(
    MARKING_STATUSES[data.status as keyof typeof MARKING_STATUSES]
  ),
  rejectReason: data.rejectReason,
  products: fp.pipe(
    fp.get('json.sntins'),
    fp.map((item) => ({ code: item }))
  )(data),
  address: data.json?.address || '—',
  dropoutReason: data.json?.dropoutReason || '—',
  dropoutReasonName: data.json.dropoutReason
    ? i18n.t(
        DROPOUT_REASON_NAMES[
          data.json.dropoutReason as keyof typeof DROPOUT_REASON_NAMES
        ]
      )
    : '—',
  participantId: data.json?.participantId || '—',
  productLineId: data.json?.productLineId || '—',
  productOrderId: data.json?.productOrderId || '—',
  sourceDocNum: data.json?.sourceDocNum || '—',
  withChild: i18n.t(data.json.withChild ? 'Да' : 'Нет'),
  createdTimestamp: format(new Date(data.createdTimestamp), 'dd.MM.yyyy HH:mm'),
  sourceDocDate: data.json.sourceDocDate
    ? format(new Date(data.json.sourceDocDate), 'dd.MM.yyyy HH:mm')
    : '—',
  original: {
    pg: data.pg,
    dropoutReason: data.dropoutReason,
    productionLineId: data.json.productLineId,
    productionOrderId: data.json.productOrderId,
    ...data.json,
    sourceDocDate: data.json.sourceDocDate
      ? new Date(data.json.sourceDocDate)
      : '',
    count: data.json.sntins.length,
  },
});
