import React, { memo, useMemo } from 'react';

import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';

import { Icon } from '../../application/icons';
import { HeaderLogo } from '../header-logo';
import { useEvent } from '../../utils/react.hooks';
import { LanguageSwitcher } from '../../application/language-switcher';
import { MenuData, PageMenu } from '../page-menu';
import { Enum, Language } from '../../application';

import {
  MainMenuContainer,
  MainMenuItem,
  MainMenu,
  MainMenuSpace,
} from './main-menu';

import css from './header.module.scss';

type MainMenu = {
  id: number;
  selected: boolean;
  label: string;
  value: string;
  url?: string;
};

type DropoutMenuItem = {
  id: number;
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
};

export type HeaderProps<T extends Language = Language> = {
  isCustomProfileVisible?: boolean;
  profileDropDownMunu?: React.ReactNode;
  exitLabel?: string;
  className?: string;
  userName: string;
  locationPathname: string;
  languages: Enum<T>;
  logout: () => void;
  onChangeSubMenu: (value: string) => void;
  changeLanguage: (language: T) => void;
  logo?: string;
  logoSmall: string;
  menuItems: MainMenu[];
  dropoutMenu?: DropoutMenuItem[];
  subMenuItems: MenuData[];
  selectedLanguage: T;
  children?: React.ReactNode;
};

const onClickMainMenuItem = (url?: string) => {
  if (url) {
    window.open(url, '_blank');
  }
};

const Component = <T extends Language = Language>({
  isCustomProfileVisible = false,
  profileDropDownMunu,
  locationPathname,
  userName,
  logo,
  logoSmall,
  logout,
  className,
  onChangeSubMenu,
  dropoutMenu = [],
  menuItems,
  subMenuItems,
  languages,
  selectedLanguage,
  changeLanguage,
  exitLabel = 'Выйти',
  children,
}: HeaderProps<T>) => {
  const onLogout = useEvent((close) => {
    close();
    logout();
  });

  const languageCodes = useMemo(
    () => languages.map(({ code }) => code),
    [languages]
  );

  return (
    <MainMenuContainer className={className}>
      <MainMenu>
        <HeaderLogo logo={logo} logoSmall={logoSmall} />
        {menuItems.map((mItem) => (
          <MainMenuItem
            key={mItem.id}
            label={mItem.label}
            selected={mItem.selected}
            onClick={() => onClickMainMenuItem(mItem?.url)}
          />
        ))}
        {isCustomProfileVisible ? <>{children}</> : <MainMenuSpace />}
        <MainMenuItem
          dropdown
          icon={<Icon className={css.profileIcon} name="Profile" />}
          label={userName}
        >
          {(close) => (
            <>
              {isCustomProfileVisible ? (
                <>{profileDropDownMunu}</>
              ) : (
                <MenuList>
                  {dropoutMenu.map((el) => (
                    <MenuItem key={el.id} onClick={el.onClick}>
                      <ListItemIcon>{el.icon}</ListItemIcon>
                      <ListItemText>{el.label}</ListItemText>
                    </MenuItem>
                  ))}
                  <MenuItem onClick={() => onLogout(close)}>
                    <ListItemIcon>
                      <Icon className={css.icon} name="SignOut" />
                    </ListItemIcon>
                    <ListItemText>{exitLabel}</ListItemText>
                  </MenuItem>
                </MenuList>
              )}
            </>
          )}
        </MainMenuItem>
        <LanguageSwitcher<T>
          languages={languageCodes}
          selected={selectedLanguage}
          onChange={changeLanguage}
        />
      </MainMenu>
      <PageMenu
        locationPathname={locationPathname}
        menuList={subMenuItems}
        onChange={onChangeSubMenu}
      />
    </MainMenuContainer>
  );
};

export const Header = memo(Component) as typeof Component;
