import React from 'react';
import styles from './index.module.scss';

import {
  Checkbox as MUICheckbox,
  CheckboxProps as MUICheckboxProps,
  FormControlLabel,
  FormControlLabelProps as MUIFormControlLabelProps,
} from '@mui/material';

export type CheckboxProps = Omit<
  MUIFormControlLabelProps,
  'color' | 'control' | 'onFocus' | 'onBlur'
> &
  Pick<
    MUICheckboxProps,
    | 'color'
    | 'disableRipple'
    | 'disableTouchRipple'
    | 'required'
    | 'onFocus'
    | 'onBlur'
  >;

export const Checkbox: React.FC<CheckboxProps> = ({
  color = 'primary',
  disableRipple = true,
  disableTouchRipple = true,
  onFocus,
  onBlur,
  ...rest
}) => (
  <FormControlLabel
    control={
      <MUICheckbox
        className={styles.container}
        color={color}
        disableRipple={disableRipple}
        disableTouchRipple={disableTouchRipple}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    }
    {...rest}
  />
);
