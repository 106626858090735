import React, { useCallback, useEffect, useMemo, useState, FC } from 'react';

import cn from 'classnames';
import {
  Box,
  Chip,
  InputAdornment,
  Stack,
  TextField,
  TextFieldProps,
} from '@mui/material';

import { Icon } from '../icons';

import css from './input-tags.module.scss';

export type InputTagsProps = Omit<TextFieldProps, 'value' | 'onChange'> & {
  value: string[] | undefined;
  onChange: (value: string[]) => void;
};

/**
 * @deprecated
 * use `<Selectbox multiply />` or `<Autocomplete multiply />` instead
 * */
export const InputTags: FC<InputTagsProps> = (props) => {
  const {
    value: tags,
    onChange,
    className,
    disabled,
    ...textFieldProps
  } = props;

  const [isNewInput, setIsNewInput] = useState<boolean>(true);
  const [duplicateValue, setDuplicateValue] = useState<string>();
  const [originalInput, setOriginalInput] = useState<string>('');

  const viewInputValue = useMemo(() => {
    const isEmpty = !duplicateValue && (isNewInput || !tags);
    return isEmpty ? '' : originalInput;
  }, [duplicateValue, tags, isNewInput, originalInput]);

  const visibleTags: string[] = useMemo(() => {
    if (Array.isArray(tags)) {
      return isNewInput ? tags : tags.slice(0, -1);
    }
    return [];
  }, [tags, isNewInput]);

  const handleInputValue = useCallback(
    (tagList: string[] | undefined, inputValue: string) => {
      if (Array.isArray(tagList)) {
        const indexOfValue = tagList.indexOf(inputValue);
        const lastIndex = tagList.length - 1;
        const isDuplicate =
          (isNewInput && indexOfValue >= 0) ||
          (!isNewInput && indexOfValue >= 0 && indexOfValue < lastIndex);

        setDuplicateValue(isDuplicate ? inputValue : undefined);

        if (isNewInput && isDuplicate) {
          return;
        } else if (!isNewInput && isDuplicate) {
          setIsNewInput(true);
          onChange(tagList.slice(0, -1));
        } else if (isNewInput && inputValue) {
          setIsNewInput(false);
          onChange([...tagList, inputValue]);
        } else if (!isNewInput && !inputValue) {
          setIsNewInput(true);
          onChange(tagList.slice(0, -1));
        } else if (!isNewInput && inputValue) {
          const newFilter = [...tagList];
          newFilter[lastIndex] = inputValue;
          onChange(newFilter);
        }
      } else if (inputValue) {
        setIsNewInput(false);
        onChange([inputValue]);
      }
    },
    [isNewInput, onChange]
  );

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setOriginalInput(event.target.value);
      const inputValue = event.target.value.trim();
      const tagList = Array.isArray(tags) ? tags : undefined;
      handleInputValue(tagList, inputValue);
    },
    [tags, handleInputValue]
  );

  const onAddTagClick = useCallback(() => {
    setIsNewInput(true);
  }, []);

  const onRemoveTagClick = useCallback(
    (value: string) => {
      if (!Array.isArray(tags)) {
        return;
      }
      const tagList = tags.filter((item) => item !== value);
      onChange(tagList);
      if (duplicateValue === value) {
        handleInputValue(tagList, duplicateValue);
      }
    },
    [duplicateValue, tags, handleInputValue, onChange]
  );

  useEffect(() => {
    if (
      Array.isArray(tags) &&
      !isNewInput &&
      tags[tags.length - 1] !== originalInput.trim()
    ) {
      setIsNewInput(true);
    }
  }, [tags, isNewInput, originalInput]);

  return (
    <Box data-testid="InputTags" className={cn(css['input-tags'], className)}>
      <TextField
        {...textFieldProps}
        InputProps={{
          autoComplete: 'off',
          endAdornment: (
            <InputAdornment position="end" onClick={onAddTagClick}>
              <Icon
                className={cn({
                  [css['icon_disabled']]: isNewInput,
                })}
                name="Plus"
              />
            </InputAdornment>
          ),
        }}
        disabled={disabled}
        value={viewInputValue}
        onChange={onInputChange}
        data-testid="InputTagsTextField"
      />
      <Stack
        className={cn(css['stack-container'], {
          [css['stack-container_mt']]: visibleTags.length,
        })}
      >
        {visibleTags.map((value) => (
          <Chip
            disabled={disabled}
            key={value}
            label={value}
            onDelete={() => onRemoveTagClick(value)}
          />
        ))}
      </Stack>
    </Box>
  );
};
