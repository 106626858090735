import React, { useEffect, useMemo, useState } from 'react';

import { Tab, Tabs } from '@mui/material';
import cn from 'classnames';

import { useEvent } from '../../utils/react.hooks';

import styles from './page-menu.module.scss';

export type MenuData<T = string> = {
  id: string;
  label: string;
  value: T;
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export type PageMenuProps = {
  menuList: MenuData[];
  locationPathname: string;
  className?: string;
  onChange: (path: string) => void;
};

export const PageMenu = React.memo<PageMenuProps>(
  ({ locationPathname, menuList, className, onChange }) => {
    const [value, setValue] = useState(locationPathname);

    const menuValues = useMemo(
      () => menuList.map(({ value }) => value),
      [menuList]
    );

    useEffect(() => {
      const [_divider, tabNameFromLocation] =
        locationPathname?.split('/') ?? '';
      const valueFromLocation = `/${tabNameFromLocation}`;
      const route = menuValues.find((el) => el.includes(valueFromLocation));
      route && setValue(route);
    }, [locationPathname, menuValues]);

    const onClickTab = useEvent((path: string) => {
      onChange(path);
      setValue(path);
    });

    return (
      <Tabs className={cn(styles.PageMenu, className)} value={value}>
        {menuList.map((item) => (
          <Tab
            className={styles.container}
            key={item.value}
            label={item.label}
            value={item.value}
            onClick={(e) =>
              item.onClick ? item.onClick(e) : onClickTab(item.value)
            }
          />
        ))}
      </Tabs>
    );
  }
);
