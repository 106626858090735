import React, { Fragment, FC } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Link } from '@mui/material';

import { Icon } from '../icons';

import style from './bread-crumbs.module.scss';

type BreadcrumbsItem = {
  label: string;
  href: string;
};

export type BreadcrumbsProps = {
  data: BreadcrumbsItem[];
};

export const BreadCrumbs: FC<BreadcrumbsProps> = ({ data }) => (
  <Box data-testid="BreadCrumbs" className={style.wrapper}>
    {data.map((crumb, index) => (
      <Fragment key={crumb.label}>
        {index !== 0 && <Icon className={style.icon} name="Chevron" />}
        {crumb?.href ? (
          <Link
            className={style.crumbLink}
            component={RouterLink}
            to={crumb.href}
          >
            <Typography className={style.crumbText} component="span">
              {crumb.label}
            </Typography>
          </Link>
        ) : (
          <Typography className={style.crumbText} component="span">
            {crumb.label}
          </Typography>
        )}
      </Fragment>
    ))}
  </Box>
);
