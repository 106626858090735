import { useState, useMemo } from 'react';

import { v1 as uuid } from 'uuid';
import { format } from 'date-fns';
import { default as queryString } from 'query-string';

import {
  FormValues,
  type Filter,
  type FilterSet,
  FiltersQueryArgs,
} from './filters-table';

type CreateFilterSetArgs = {
  filters: Filter[];
  name?: string;
  tab?: string;
};

const createFilterSet = ({
  filters,
  name,
  tab,
}: CreateFilterSetArgs): FilterSet => {
  const date = format(new Date(), 'dd.MM.yyyy HH:mm');
  return { id: uuid(), name: name ?? date, filters, tab };
};

type UseFiltersTableArgs = {
  tab?: string;
  storedHistoryFilters: FilterSet[];
  storeHistoryFilters: (value: FilterSet[]) => void;
  storedSavedFilters: FilterSet[];
  storeSavedFilters: (value: FilterSet[]) => void;
};

type UseFiltersTable = {
  initialFilters?: FormValues;
  historyFilters: FilterSet[];
  savedFilters: FilterSet[];
  setInitialFilters: (filters: FormValues) => void;
  handleAddFiltersHistory: (filters: Filter[]) => void;
  handleSaveFilters: (filters: Filter[], name: string) => void;
  handleEditSavedFilter: (newFilterSet: FilterSet) => void;
  handleDeleteSavedFilters: (id: string) => void;
};

export const useFiltersTable = ({
  tab,
  storedHistoryFilters,
  storeHistoryFilters,
  storedSavedFilters,
  storeSavedFilters,
}: UseFiltersTableArgs): UseFiltersTable => {
  const locationFormValues = queryString.parse(
    location.search
  ) as FiltersQueryArgs;

  const [initialFilters, setInitialFilters] =
    useState<FormValues>(locationFormValues);

  const [historyFilters, setHistoryFilters] =
    useState<FilterSet[]>(storedHistoryFilters);
  const [savedFilters, setSavedFilters] =
    useState<FilterSet[]>(storedSavedFilters);

  const handleAddFiltersHistory = (filters: Filter[]) => {
    if (filters.length) {
      const newFilterSet = createFilterSet({ filters, tab });
      const newHistoryFilters = [...historyFilters, newFilterSet];
      setHistoryFilters(newHistoryFilters);
      storeHistoryFilters(newHistoryFilters);
    }
  };

  const handleDeleteSavedFilters = (id: string) => {
    const newSavedFilters = savedFilters.filter((filter) => filter.id !== id);
    setSavedFilters(newSavedFilters);
    storeSavedFilters(newSavedFilters);
  };

  const handleEditSavedFilter = (newFilterSet: FilterSet) => {
    const newSavedFilters = savedFilters.map((filterSet) =>
      filterSet.id === newFilterSet.id ? newFilterSet : filterSet
    );
    setSavedFilters(newSavedFilters);
    storeSavedFilters(newSavedFilters);
  };

  const handleSaveFilters = (filters: Filter[], name: string) => {
    const newFilterSet = createFilterSet({ filters, tab, name });
    const newSavedFilters = [...savedFilters, newFilterSet];
    setSavedFilters(newSavedFilters);
    storeSavedFilters(newSavedFilters);
  };

  const historyTabFilters = useMemo(
    () =>
      tab === undefined
        ? historyFilters
        : historyFilters.filter((filter) => filter.tab === tab),
    [historyFilters, tab]
  );

  const savedTabFilters = useMemo(
    () =>
      tab === undefined
        ? savedFilters
        : savedFilters.filter((filter) => filter.tab === tab),
    [savedFilters, tab]
  );

  return {
    initialFilters,
    historyFilters: historyTabFilters,
    savedFilters: savedTabFilters,
    setInitialFilters,
    handleAddFiltersHistory,
    handleSaveFilters,
    handleEditSavedFilter,
    handleDeleteSavedFilters,
  };
};
