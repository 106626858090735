import React from 'react';

import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';
import styles from './button.module.scss';

export type ButtonProps = MuiButtonProps;

export const Button = React.memo<ButtonProps>(
  ({ children, variant = 'contained', ...rest }) => (
    <div className={styles.container}>
      <MuiButton variant={variant} {...rest}>
        {children}
      </MuiButton>
    </div>
  )
);
