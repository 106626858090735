import React from 'react';

import cn from 'classnames';
import { Box } from '@mui/material';

import styles from './common-styles.module.scss';

interface DropDownIconProps {
  open?: boolean;
  color?: string;
}

export function DropDownMarker({ open }: DropDownIconProps) {
  return (
    <Box
      className={cn(styles.DropDownMarkerRoot, {
        [styles.DropDownMarkerRootOpen]: open,
      })}
      color="inherit"
    />
  );
}
