import React from 'react';

import { FieldRenderProps } from 'react-final-form';
import cn from 'classnames';
import { Box } from '@mui/material';

import { Select, SelectProps } from '../../mui/select';
import { useEvent } from '../../utils/react.hooks';

import styles from './select-field.module.scss';

export type SelectFieldProps<T extends string | number = string> =
  SelectProps<T> & FieldRenderProps<T, HTMLElement>;

export const SelectField = <T extends string | number = string>(
  props: SelectFieldProps<T>
) => {
  const {
    input: {
      name,
      value,
      multiple,
      onBlur: onBlurField,
      onChange: onChangeField,
      onFocus: onFocusField,
    },
    disabled,
    required,
    meta,
    label,
    fullWidth = true,
    onChange,
    onBlur,
    onFocus,
    ...selectProps
  } = props;

  type Props = Required<SelectFieldProps<T>>;

  const handleBlur = useEvent<Props['onBlur']>((event) => {
    onBlurField(event);
    onBlur?.(event);
  });

  const handleChange = useEvent<Props['onChange']>((event, child) => {
    onChangeField(event);
    onChange?.(event, child);
  });

  const handleFocus = useEvent<Props['onFocus']>((event) => {
    onFocusField(event);
    onFocus?.(event);
  });

  const isVisibleError = !disabled && (meta.touched || meta.modified);
  const hasError = isVisibleError && !!meta.error;

  return (
    <Box
      className={cn(styles.formControl, {
        [styles.formControlWithError]: hasError,
      })}
    >
      <Select
        {...selectProps}
        className={cn({
          [styles.fieldRequiredError]: (!value && required) || hasError,
        })}
        disabled={disabled}
        error={hasError}
        errorText={meta.error}
        fullWidth={fullWidth}
        hasError={hasError}
        label={label}
        multiple={multiple}
        name={name}
        required={required}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
      />
    </Box>
  );
};
