import React from 'react';

import { toast, ToastContainer, ToastPosition } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import style from './toast.module.scss';

const AUTO_CLOSE_PERIOD_MS = 5 * 1000;

type ParsedError = {
  id: string;
  name: string[] | string;
};

export type ToastWrapperProps = {
  closeOnClick?: boolean | undefined;
  position?: ToastPosition | undefined;
};

const getErrorsList = (
  errors: Error,
  parseErrors: (errors: Error) => ParsedError[]
) => {
  return parseErrors(errors).map((el) => (
    <span key={el.id}>
      {Array.isArray(el?.name) ? <span>{el.name.join('. ')}</span> : el.name}
    </span>
  ));
};

export type ToastProps = {
  content?: string;
  errors?: Error;
  autoCloseDisabled?: boolean;
};

export const showError = (
  parseErrors: (errors: Error) => ParsedError[],
  { content, errors, autoCloseDisabled }: ToastProps
) => {
  return toast(
    <div className={style['Toast-content']}>
      {errors ? getErrorsList(errors, parseErrors) : content}
    </div>,
    {
      className: style['error-toast-container'],
      closeOnClick: true,
      autoClose: autoCloseDisabled ? false : AUTO_CLOSE_PERIOD_MS,
      hideProgressBar: true,
      closeButton: true,
    }
  );
};

export const showSuccess = ({ content, autoCloseDisabled }: ToastProps) => {
  return toast(<div className={style['Toast-content']}>{content}</div>, {
    className: style['success-toast-container'],
    closeOnClick: true,
    autoClose: autoCloseDisabled ? false : AUTO_CLOSE_PERIOD_MS,
    hideProgressBar: true,
    closeButton: true,
  });
};

export const ToastWrapper = ({
  closeOnClick = false,
  position = toast.POSITION.BOTTOM_LEFT,
}: ToastWrapperProps) => {
  return (
    <ToastContainer
      closeOnClick={closeOnClick}
      data-testid="ToastWrapper"
      position={position}
    />
  );
};
