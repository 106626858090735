export const COUNTRIES = [
  { label: 'АНДОРРА', value: 'AD' },
  { label: 'МАРТИНИКА', value: 'MQ' },
  { label: 'СЛОВЕНИЯ', value: 'SI' },
  { label: 'КОСТА-РИКА', value: 'CR' },
  { label: 'ВЕНГРИЯ', value: 'HU' },
  { label: 'ШПИЦБЕРГЕН И ЯН МАЙЕН', value: 'SJ' },
  { label: 'ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ', value: 'AE' },
  { label: 'МАВРИТАНИЯ', value: 'MR' },
  { label: 'КУБА', value: 'CU' },
  { label: 'СЛОВАКИЯ', value: 'SK' },
  { label: 'АФГАНИСТАН', value: 'AF' },
  { label: 'ИНДОНЕЗИЯ', value: 'ID' },
  { label: 'МОНТСЕРРАТ', value: 'MS' },
  { label: 'КАБО-ВЕРДЕ', value: 'CV' },
  { label: 'СЬЕРРА-ЛЕОНЕ', value: 'SL' },
  { label: 'АНТИГУА И БАРБУДА', value: 'AG' },
  { label: 'ИРЛАНДИЯ', value: 'IE' },
  { label: 'МАЛЬТА', value: 'MT' },
  { label: 'КЮРАСАО', value: 'CW' },
  { label: 'САН-МАРИНО', value: 'SM' },
  { label: 'АНГИЛЬЯ', value: 'AI' },
  { label: 'ИЗРАИЛЬ', value: 'IL' },
  { label: 'МАВРИКИЙ', value: 'MU' },
  { label: 'ОСТРОВ РОЖДЕСТВА', value: 'CX' },
  { label: 'АЛБАНИЯ', value: 'AL' },
  { label: 'СЕНЕГАЛ', value: 'SN' },
  { label: 'ОСТРОВ МЭН', value: 'IM' },
  { label: 'МАЛЬДИВЫ', value: 'MV' },
  { label: 'КИПР', value: 'CY' },
  { label: 'АРМЕНИЯ', value: 'AM' },
  { label: 'СОМАЛИ', value: 'SO' },
  { label: 'ИНДИЯ', value: 'IN' },
  { label: 'МАЛАВИ', value: 'MW' },
  { label: 'СУРИНАМ', value: 'SR' },
  { label: 'БРИТАНСКАЯ ТЕРРИТОРИЯ В ИНДИЙСКОМ ОКЕАНЕ', value: 'IO' },
  { label: 'МЕКСИКА', value: 'MX' },
  { label: 'ГЕРМАНИЯ', value: 'DE' },
  { label: 'АНГОЛА', value: 'AO' },
  { label: 'ЮЖНЫЙ СУДАН', value: 'SS' },
  { label: 'ИРАК', value: 'IQ' },
  { label: 'МАЛАЙЗИЯ', value: 'MY' },
  { label: 'ДЖИБУТИ', value: 'DJ' },
  { label: 'АНТАРКТИДА', value: 'AQ' },
  { label: 'САН-ТОМЕ И ПРИНСИПИ', value: 'ST' },
  { label: 'ИРАН (ИСЛАМСКАЯ РЕСПУБЛИКА)', value: 'IR' },
  { label: 'МОЗАМБИК', value: 'MZ' },
  { label: 'ДАНИЯ', value: 'DK' },
  { label: 'АРГЕНТИНА', value: 'AR' },
  { label: 'ЭЛЬ-САЛЬВАДОР', value: 'SV' },
  { label: 'ИСЛАНДИЯ', value: 'IS' },
  { label: 'НАМИБИЯ', value: 'NA' },
  { label: 'ДОМИНИКА', value: 'DM' },
  { label: 'АМЕРИКАНСКОЕ САМОА', value: 'AS' },
  { label: 'СЕН-МАРТЕН (нидерландская часть)', value: 'SX' },
  { label: 'ИТАЛИЯ', value: 'IT' },
  { label: 'НОВАЯ КАЛЕДОНИЯ', value: 'NC' },
  { label: 'ДОМИНИКАНСКАЯ РЕСПУБЛИКА', value: 'DO' },
  { label: 'АВСТРИЯ', value: 'AT' },
  { label: 'СИРИЙСКАЯ АРАБСКАЯ РЕСПУБЛИКА', value: 'SY' },
  { label: 'ДЖЕРСИ', value: 'JE' },
  { label: 'НИГЕР', value: 'NE' },
  { label: 'АЛЖИР', value: 'DZ' },
  { label: 'АВСТРАЛИЯ', value: 'AU' },
  { label: 'ОСТРОВ НОРФОЛК', value: 'NF' },
  { label: 'ЯМАЙКА', value: 'JM' },
  { label: 'ЭКВАДОР', value: 'EC' },
  { label: 'АРУБА', value: 'AW' },
  { label: 'ОСТРОВА ТЕРКС И КАЙКОС', value: 'TC' },
  { label: 'НИГЕРИЯ', value: 'NG' },
  { label: 'ИОРДАНИЯ', value: 'JO' },
  { label: 'ЭСТОНИЯ', value: 'EE' },
  { label: 'ЭЛАНДСКИЕ ОСТРОВА', value: 'AX' },
  { label: 'ЧАД', value: 'TD' },
  { label: 'НИКАРАГУА', value: 'NI' },
  { label: 'ЯПОНИЯ', value: 'JP' },
  { label: 'ЕГИПЕТ', value: 'EG' },
  { label: 'АЗЕРБАЙДЖАН', value: 'AZ' },
  { label: 'ФРАНЦУЗСКИЕ ЮЖНЫЕ ТЕРРИТОРИИ', value: 'TF' },
  { label: 'НИДЕРЛАНДЫ', value: 'NL' },
  { label: 'КЕНИЯ', value: 'KE' },
  { label: 'ЗАПАДНАЯ САХАРА', value: 'EH' },
  { label: 'БОСНИЯ И ГЕРЦЕГОВИНА', value: 'BA' },
  { label: 'ТОГО', value: 'TG' },
  { label: 'НОРВЕГИЯ', value: 'NO' },
  { label: 'ЭРИТРЕЯ', value: 'ER' },
  { label: 'БАРБАДОС', value: 'BB' },
  { label: 'ТАИЛАНД', value: 'TH' },
  { label: 'НЕПАЛ', value: 'NP' },
  { label: 'КАМБОДЖА', value: 'KH' },
  { label: 'ИСПАНИЯ', value: 'ES' },
  { label: 'БАНГЛАДЕШ', value: 'BD' },
  { label: 'ТАДЖИКИСТАН', value: 'TJ' },
  { label: 'НАУРУ', value: 'NR' },
  { label: 'КИРИБАТИ', value: 'KI' },
  { label: 'ЭФИОПИЯ', value: 'ET' },
  { label: 'БЕЛЬГИЯ', value: 'BE' },
  { label: 'ТОКЕЛАУ', value: 'TK' },
  { label: 'НИУЭ', value: 'NU' },
  { label: 'КОМОРЫ', value: 'KM' },
  { label: 'ФИНЛЯНДИЯ', value: 'FI' },
  { label: 'БУРКИНА-ФАСО', value: 'BF' },
  { label: 'ТИМОР-ЛЕСТЕ', value: 'TL' },
  { label: 'НОВАЯ ЗЕЛАНДИЯ', value: 'NZ' },
  { label: 'СЕНТ-КИТС И НЕВИС', value: 'KN' },
  { label: 'ФИДЖИ', value: 'FJ' },
  { label: 'БОЛГАРИЯ', value: 'BG' },
  { label: 'ОМАН', value: 'OM' },
  { label: 'КОРЕЯ (НАРОДНО-ДЕМОКРАТИЧ. РЕСПУБЛИКА)', value: 'KP' },
  { label: 'ФОЛКЛЕНДСКИЕ ОСТРОВА (МАЛЬВИНСКИЕ)', value: 'FK' },
  { label: 'БАХРЕЙН', value: 'BH' },
  { label: 'ТУНИС', value: 'TN' },
  { label: 'ПАНАМА', value: 'PA' },
  { label: 'КОРЕЯ', value: 'KR' },
  { label: 'МИКРОНЕЗИЯ (ФЕДЕРАТИВНЫЕ ШТАТЫ)', value: 'FM' },
  { label: 'БУРУНДИ', value: 'BI' },
  { label: 'ТОНГА', value: 'TO' },
  { label: 'ПЕРУ', value: 'PE' },
  { label: 'КУВЕЙТ', value: 'KW' },
  { label: 'ФАРЕРСКИЕ ОСТРОВА', value: 'FO' },
  { label: 'БЕНИН', value: 'BJ' },
  { label: 'ТУРЦИЯ', value: 'TR' },
  { label: 'ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ', value: 'PF' },
  { label: 'ОСТРОВА КАЙМАН', value: 'KY' },
  { label: 'ФРАНЦИЯ', value: 'FR' },
  { label: 'СЕН-БАРТЕЛЕМИ', value: 'BL' },
  { label: 'ТРИНИДАД И ТОБАГО', value: 'TT' },
  { label: 'ПАПУА НОВАЯ ГВИНЕЯ', value: 'PG' },
  { label: 'КАЗАХСТАН', value: 'KZ' },
  { label: 'ГАБОН', value: 'GA' },
  { label: 'БЕРМУДЫ', value: 'BM' },
  { label: 'ТУВАЛУ', value: 'TV' },
  { label: 'ФИЛИППИНЫ', value: 'PH' },
  { label: 'ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧ. РЕСПУБЛИКА', value: 'LA' },
  { label: 'СОЕДИНЕННОЕ КОРОЛЕВСТВО', value: 'GB' },
  { label: 'БРУНЕЙ-ДАРУССАЛАМ', value: 'BN' },
  { label: 'ТАЙВАНЬ (КИТАЙ)', value: 'TW' },
  { label: 'ПАКИСТАН', value: 'PK' },
  { label: 'ЛИВАН', value: 'LB' },
  { label: 'ГРЕНАДА', value: 'GD' },
  { label: 'БОЛИВИЯ (МНОГОНАЦИОНАЛЬНОЕ ГОСУДАРСТВО)', value: 'BO' },
  { label: 'ТАНЗАНИЯ', value: 'TZ' },
  { label: 'ПОЛЬША', value: 'PL' },
  { label: 'СЕНТ-ЛЮСИЯ', value: 'LC' },
  { label: 'ГРУЗИЯ', value: 'GE' },
  { label: 'БОНЭЙР', value: 'BQ' },
  { label: 'УКРАИНА', value: 'UA' },
  { label: 'УЗБЕКИСТАН', value: 'UZ' },
  { label: 'СЕНТ-ПЬЕР И МИКЕЛОН', value: 'PM' },
  { label: 'ЛИХТЕНШТЕЙН', value: 'LI' },
  { label: 'ФРАНЦУЗСКАЯ ГВИАНА', value: 'GF' },
  { label: 'БРАЗИЛИЯ', value: 'BR' },
  { label: 'УГАНДА', value: 'UG' },
  { label: 'ПИТКЭРН', value: 'PN' },
  { label: 'ШРИ-ЛАНКА', value: 'LK' },
  { label: 'ГЕРНСИ', value: 'GG' },
  { label: 'БАГАМЫ', value: 'BS' },
  { label: 'ПУЭРТО-РИКО', value: 'PR' },
  { label: 'ЛИБЕРИЯ', value: 'LR' },
  { label: 'ГАНА', value: 'GH' },
  { label: 'БУТАН', value: 'BT' },
  { label: 'СОЕДИНЕННЫЕ ШТАТЫ', value: 'US' },
  { label: 'ПАЛЕСТИНА', value: 'PS' },
  { label: 'ЛЕСОТО', value: 'LS' },
  { label: 'ГИБРАЛТАР', value: 'GI' },
  { label: 'ОСТРОВ БУВЕ', value: 'BV' },
  { label: 'УРУГВАЙ', value: 'UY' },
  { label: 'ПОРТУГАЛИЯ', value: 'PT' },
  { label: 'ЛИТВА', value: 'LT' },
  { label: 'ГРЕНЛАНДИЯ', value: 'GL' },
  { label: 'БОТСВАНА', value: 'BW' },
  { label: 'ПАЛАУ', value: 'PW' },
  { label: 'ЛЮКСЕМБУРГ', value: 'LU' },
  { label: 'ГАМБИЯ', value: 'GM' },
  { label: 'БЕЛАРУСЬ', value: 'BY' },
  { label: 'ПАРАГВАЙ', value: 'PY' },
  { label: 'ЛАТВИЯ', value: 'LV' },
  { label: 'ГВИНЕЯ', value: 'GN' },
  { label: 'БЕЛИЗ', value: 'BZ' },
  { label: 'СЕНТ-ВИНСЕНТ И ГРЕНАДИНЫ', value: 'VC' },
  { label: 'КАТАР', value: 'QA' },
  { label: 'ЛИВИЯ', value: 'LY' },
  { label: 'ГВАДЕЛУПА', value: 'GP' },
  { label: 'КАНАДА', value: 'CA' },
  { label: 'ВЕНЕСУЭЛА (БОЛИВАРИАНСКАЯ РЕСПУБЛИКА)', value: 'VE' },
  { label: 'РЕЮНЬОН', value: 'RE' },
  { label: 'МАРОККО', value: 'MA' },
  { label: 'ЭКВАТОРИАЛЬНАЯ ГВИНЕЯ', value: 'GQ' },
  { label: 'КОКОСОВЫЕ (КИЛИНГ) ОСТРОВА', value: 'CC' },
  { label: 'ВИРГИНСКИЕ ОСТРОВА (БРИТАНСКИЕ)', value: 'VG' },
  { label: 'РУМЫНИЯ', value: 'RO' },
  { label: 'МОНАКО', value: 'MC' },
  { label: 'ГРЕЦИЯ', value: 'GR' },
  { label: 'КОНГО', value: 'CD' },
  { label: 'ВИРГИНСКИЕ ОСТРОВА (США)', value: 'VI' },
  { label: 'СЕРБИЯ', value: 'RS' },
  { label: 'МОЛДОВА', value: 'MD' },
  { label: 'ЦЕНТРАЛЬНО-АФРИКАНСКАЯ РЕСПУБЛИКА', value: 'CF' },
  { label: 'ВЬЕТНАМ', value: 'VN' },
  { label: 'РОССИЯ', value: 'RU' },
  { label: 'ЧЕРНОГОРИЯ', value: 'ME' },
  { label: 'ГВАТЕМАЛА', value: 'GT' },
  { label: 'КОНГО', value: 'CG' },
  { label: 'ВАНУАТУ', value: 'VU' },
  { label: 'РУАНДА', value: 'RW' },
  { label: 'ГУАМ', value: 'GU' },
  { label: 'ШВЕЙЦАРИЯ', value: 'CH' },
  { label: 'УОЛЛИС И ФУТУНА', value: 'WF' },
  { label: 'САУДОВСКАЯ АРАВИЯ', value: 'SA' },
  { label: 'МАДАГАСКАР', value: 'MG' },
  { label: 'ГВИНЕЯ-БИСАУ', value: 'GW' },
  { label: "КОТ Д'ИВУАР", value: 'CI' },
  { label: 'САМОА', value: 'WS' },
  { label: 'СОЛОМОНОВЫ ОСТРОВА', value: 'SB' },
  { label: 'МАРШАЛЛОВЫ ОСТРОВА', value: 'MH' },
  { label: 'ГАЙАНА', value: 'GY' },
  { label: 'ОСТРОВА КУКА', value: 'CK' },
  { label: 'ЙЕМЕН', value: 'YE' },
  { label: 'СЕЙШЕЛЫ', value: 'SC' },
  { label: 'РЕСПУБЛИКА МАКЕДОНИЯ', value: 'MK' },
  { label: 'ГОНКОНГ', value: 'HK' },
  { label: 'ЧИЛИ', value: 'CL' },
  { label: 'МАЙОТТА', value: 'YT' },
  { label: 'СУДАН', value: 'SD' },
  { label: 'МАЛИ', value: 'ML' },
  { label: 'ОСТРОВ ХЕРД И ОСТРОВА МАКДОНАЛЬД', value: 'HM' },
  { label: 'КАМЕРУН', value: 'CM' },
  { label: 'ЮЖНАЯ АФРИКА', value: 'ZA' },
  { label: 'ШВЕЦИЯ', value: 'SE' },
  { label: 'МЬЯНМА', value: 'MM' },
  { label: 'ГОНДУРАС', value: 'HN' },
  { label: 'КИТАЙ', value: 'CN' },
  { label: 'ЗАМБИЯ', value: 'ZM' },
  { label: 'СИНГАПУР', value: 'SG' },
  { label: 'МОНГОЛИЯ', value: 'MN' },
  { label: 'ХОРВАТИЯ', value: 'HR' },
  { label: 'КОЛУМБИЯ', value: 'CO' },
  { label: 'ЗИМБАБВЕ', value: 'ZW' },
  { label: 'СВ. ЕЛЕНА', value: 'SH' },
  { label: 'МАКАО', value: 'MO' },
  { label: 'ГАИТИ', value: 'HT' },
  { label: 'СЕВЕРНЫЕ МАРИАНСКИЕ ОСТРОВА', value: 'MP' },
  { label: 'КЫРГЫЗСТАН', value: 'KG' },
  { label: 'ЧЕХИЯ', value: 'CZ' },
  { label: 'ЮЖН. ДЖОРДЖИЯ И ЮЖН. САНДВИЧ. ОСТРОВА', value: 'GS' },
  { label: 'СЕН-МАРТЕН (ФРАНЦУЗСКАЯ ЧАСТЬ)', value: 'MF' },
  { label: 'ЭСВАТИНИ', value: 'SZ' },
  { label: 'ТУРКМЕНИСТАН', value: 'TM' },
  { label: 'МАЛЫЕ ТИХООКЕАН. ОТДАЛЕН. ОСТ-ВА С.Ш.', value: 'UM' },
  { label: 'ПАПСКИЙ ПРЕСТОЛ (ГОС.-ГОРОД ВАТИКАН)', value: 'VA' },
];
