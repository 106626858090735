import { put } from 'redux-saga/effects';
import { format } from 'date-fns';

import { showErrorToast } from '../../components/toastWrapper';
import { API } from '../../api';
import { setIsLoading, setSettings } from '../actions/common';

export function* downloadFileSaga(action) {
  try {
    const date = format(new Date(), 'yyyyMMddhhmmss');
    const fileName = `_download_${date}.csv_`;
    yield API.rest.files.download(action.payload, fileName);
  } catch (err) {
    yield showErrorToast({ errors: err });
  }
}

export function* uploadFileSaga(action) {
  try {
    const json = yield API.rest.codes.validate(
      [...action.payload.files],
      '',
      action.payload.query
    );
    action.payload.onFinish(json);
  } catch (err) {
    console.error('err', err);
    action.payload.onFinish(false);
    yield showErrorToast({ errors: err });
  }
}

export function* loadSettingsSaga() {
  try {
    yield put(setIsLoading(true));
    yield put(setIsLoading(false));
    const json = yield API.rest.settings.item();
    yield put(setSettings(json));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* uploadFileWithIdentificationSaga(action) {
  try {
    const json = yield API.rest.codes.identificationValidate(
      [...action.payload.files],
      '',
      action.payload.query,
      action.payload.type
    );
    action.payload.onFinish(json);
  } catch (err) {
    action.payload.onFinish(false);
    yield showErrorToast({ errors: err });
  }
}
