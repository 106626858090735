import React, { FC, useState, useEffect } from 'react';

import { Tabs, TabProps } from '@mui/material';

import { Tab } from './tab';

import styles from './index.module.scss';

export type TabComponentProps = {
  items: TabProps[];
  variant: 'standard' | 'scrollable' | 'fullWidth';
  onChangeTab: (v: number) => void;
  tab?: number;
};

export const TabsComponent: FC<TabComponentProps> = ({
  items = [],
  variant = 'standard',
  onChangeTab,
  tab,
  ...rest
}) => {
  const [value, setValue] = useState(tab ? tab : 0);

  const onChange = (_event: Object, value: number) => {
    setValue(value);
    onChangeTab(value);
  };

  useEffect(() => {
    if (tab >= 0 && tab !== value) {
      setValue(tab);
    }
  }, [tab]);

  return (
    <Tabs
      className={styles.container}
      value={value}
      variant={variant}
      onChange={onChange}
      {...rest}
    >
      {items.map((item, index) => (
        <Tab key={index} {...item} />
      ))}
    </Tabs>
  );
};
