import React, { useCallback, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import fp from 'lodash/fp';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Header } from '@ibox/ui';

import { isMpOperator } from '../../../helpers';
import { routes } from '../../../routes';
import {
  getCookie,
  getShortName,
  isAdmin,
  setCookie,
} from '../../../utils/utils';
import { logout } from '../../../store/actions/auth';
import { saveLanguage } from '../../../store/actions/common';
import { Language, Name, RootState } from '../../../store/reducers';

import Logo from '../../../assets/logos/AM/logo_am.png';

type Enum = {
  code: Language;
  translations: Name;
}[];

const menuItems = [
  {
    id: 1,
    selected: false,
    label: 'Реестр товаров',
    value: 'rmt',
    url: `${window.env.REACT_APP_RMT_URL}/`,
  },
  {
    id: 2,
    selected: false,
    label: 'Маркировка товаров',
    value: 'mark',
    url: `${window.env.REACT_APP_ELK_URL}/#/dashboard`,
  },
  {
    id: 3,
    selected: true,
    label: 'Заказ кодов',
    value: 'icom',
  },
];

const subMenuItems = [
  {
    id: 'orders',
    label: 'Заказы',
    value: routes.orders(),
    role: 'ALL',
  },
  {
    id: 'reports',
    label: 'Отчеты',
    value: routes.documents(),
    role: 'ALL',
  },
  {
    id: 'serviceProviders',
    label: 'Сервис-провайдеры',
    value: routes.serviceProviders(),
    role: 'DISTRIBUTOR',
  },
  // {
  //   id: 'serviceDistributions',
  //   label: 'Сети распределения',
  //   value: routes.serviceDistributions(),
  //   role: 'OPERATOR',
  // },
  {
    id: 'devices',
    label: 'Устройства',
    value: routes.devices(),
    role: 'ADMIN',
  },
];

const languages: Enum = [
  {
    code: 'en',
    translations: {
      en: 'English',
      ru: 'Английский',
      kz: 'Ағылшын',
      uz: 'Ingliz',
      hy: 'Անգլերեն',
    },
  },
  {
    code: 'ru',
    translations: {
      en: 'Russian',
      ru: 'Русский',
      kz: 'орыс',
      uz: 'Rus',
      hy: 'Ingliz',
    },
  },
  {
    code: 'hy',
    translations: {
      en: 'Armenian',
      hy: 'Հայերեն',
      ru: 'Армянский',
      kz: 'Армянча',
      uz: 'arman',
    },
  },
];

const availableLanguages = languages.map(({ code }) => code);

const languageInCookie = getCookie('language');

const currentLanguage = [
  'ru',
  window.env.COUNTRY_CODE === 'AM'
    ? 'hy'
    : window.env.COUNTRY_CODE.toLowerCase(),
];

export const HeaderView = () => {
  const { i18n, t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();

  const dsp = useDispatch();

  const user = useSelector((state: RootState) => state?.currentUser);
  const participant = useSelector(
    (state: RootState) => state?.participants?.item
  );
  const pgList = useSelector(
    (state: RootState) => state?.currentUser?.product_group_info
  );

  const userRoles = useMemo(() => {
    const roles = ['ALL'];
    if (participant.distribution) {
      roles.push('DISTRIBUTOR');
    }
    if (isMpOperator(pgList)) {
      roles.push('OPERATOR');
    }
    if (isAdmin()) {
      roles.push('ADMIN');
    }
    return roles;
  }, [pgList, participant]);

  const handleHistoryPush = useCallback(
    (newValue: string) => history.push(newValue),
    []
  );

  const menuItemsList = useMemo(
    () => menuItems.map((el) => ({ ...el, label: t(el.label) })),
    [t]
  );

  const subMenuList = useMemo(() => {
    return subMenuItems
      .filter((item) => userRoles.includes(item.role))
      .map((menuItem) => ({
        ...menuItem,
        handleTabClick: handleHistoryPush,
        label: t(menuItem.label),
      }));
  }, [t, handleHistoryPush, userRoles]);

  const currentLanguages = useMemo(
    () => languages.filter((el) => currentLanguage.includes(el.code)),
    []
  );
  const userName = useMemo(
    () => (!fp.isEmpty(user) ? getShortName(user.full_name) : ''),
    [user]
  );

  const onLogout = () => {
    dsp(logout());
  };

  const changeLanguage = useCallback(
    (lang: Language) => {
      i18n.changeLanguage(lang);
      dsp(saveLanguage(lang));
      setCookie('language', lang.toLowerCase());
    },
    [dsp, i18n]
  );

  useEffect(() => {
    if (!availableLanguages.includes(languageInCookie)) {
      changeLanguage('ru');
    }
  }, [changeLanguage]);

  const exitLabel = t('Выйти');
  const selectedLanguage = availableLanguages.includes(languageInCookie)
    ? languageInCookie
    : 'ru';

  return (
    <div>
      <Header
        changeLanguage={changeLanguage}
        exitLabel={exitLabel}
        languages={currentLanguages}
        locationPathname={pathname}
        logoSmall={Logo}
        logout={onLogout}
        menuItems={menuItemsList}
        selectedLanguage={selectedLanguage as Language}
        subMenuItems={subMenuList}
        userName={userName}
        onChangeSubMenu={handleHistoryPush}
      />
    </div>
  );
};
