import React, { FC } from 'react';

import {
  RadioGroup as MuiRadioGroup,
  FormControl,
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material';

import { Radio, RadioProps } from '../radio';

export type RadioGroupProps = {
  options: RadioProps[];
} & MuiRadioGroupProps;

export const RadioGroup: FC<RadioGroupProps> = ({ options = [], ...rest }) => (
  <FormControl>
    <MuiRadioGroup {...rest}>
      {options.map((option, index) => (
        <Radio key={index} {...option} />
      ))}
    </MuiRadioGroup>
  </FormControl>
);
