import React, { FC, ReactNode, useEffect, useState } from 'react';

import fp from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@ibox/ui';

import { check } from '../../store/actions/auth';
import { getProfileData } from '../../store/actions/participants';
import { getCookie, setCookie } from '../../utils/utils';
import { RootState } from '../../store/reducers';

type AuthProps = {
  children: ReactNode;
};

export const Auth: FC<AuthProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector<RootState, RootState['auth']>((state) => state.auth);

  useEffect(() => {
    const language = getCookie('language') || 'ru';
    if (!language) {
      setCookie('language', 'ru');
      i18n.changeLanguage('RU');
    } else {
      i18n.changeLanguage(language);
    }
    dispatch(
      check({
        onFinish: (isNeedLogin: boolean) => {
          if (!isNeedLogin) {
            setTimeout(() => {
              dispatch(getProfileData());
            }, 300);
          }
          setIsLoading(false);
        },
      })
    );
  }, []);

  return fp.isEmpty(auth) || isLoading ? (
    <div className="spinnerWrap">
      <Spinner />
    </div>
  ) : (
    <>{children}</>
  );
};
