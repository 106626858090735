/* eslint-disable @typescript-eslint/naming-convention */
import { put, call } from 'redux-saga/effects';
import fp from 'lodash/fp';

import { API } from '../../api';
import { clearUser, setAuthData, setUser } from '../actions/auth';
import {
  deleteAllCookies,
  getCookie,
  getParamFromToken,
  getToken,
} from '../../utils/utils';
import { clearProfile } from '../actions/participants';
import { showErrorToast } from '../../components/toastWrapper';

import { loadProfileSaga } from './participants';

export function storeTokens({
  access_token,
  exp_date,
  refresh_token,
  exp_date_refresh,
}: any = {}) {
  localStorage.setItem('access_token', access_token || '');
  localStorage.setItem('refresh_token', refresh_token || '');
  localStorage.setItem('exp_date', exp_date || '');
  localStorage.setItem('exp_date_refresh', exp_date_refresh || '');
  localStorage.setItem('token_type', 'Bearer');
}

const pointOfEnter = getCookie('pointOfEnter') || '/login-kep';

function* setAuthDataSaga(json) {
  try {
    const exp_date = getParamFromToken('exp', json, 'access_token');
    const exp_date_refresh = getParamFromToken('exp', json, 'refresh_token');
    storeTokens({ ...json, exp_date, exp_date_refresh });
    yield put(
      setAuthData({
        ...json,
        exp_date,
        exp_date_refresh,
      })
    );
    yield put(setUser({ ...json }));
  } catch (err) {
    yield showErrorToast({ errors: err });
  }
}

export function* loginSaga({ payload }) {
  try {
    const json = yield API.rest.auth.signin(payload);
    yield call(setAuthDataSaga, json);
    yield call(loadProfileSaga);
  } catch (err) {
    yield showErrorToast({ errors: err });
  }
}

export function* logoutSaga() {
  try {
    window.open(`${window.env.REACT_APP_ELK_URL}${pointOfEnter}`, '_self');
    storeTokens();
    yield put(setAuthData({ isNotAuth: true }));
    yield put(clearUser());
    yield put(clearProfile());
    deleteAllCookies();
  } catch (err) {
    yield showErrorToast({ errors: err });
  }
}
export function* checkAuthSaga({ payload }) {
  try {
    const token = getToken();
    const data = fp.omitBy(fp.isEmpty, { access_token: token });
    if (data.access_token) {
      yield put(setAuthData({ ...data }));
      yield put(setUser({ access_token: data.access_token }));
      yield payload.onFinish(false);
    } else {
      window.open(`${window.env.REACT_APP_ELK_URL}${pointOfEnter}`, '_self');
    }
  } catch (err) {
    yield showErrorToast({ errors: err });
    window.open(`${window.env.REACT_APP_ELK_URL}${pointOfEnter}`, '_self');
  }
}
