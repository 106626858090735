import React, { memo } from 'react';

import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  ListRowRenderer,
} from 'react-virtualized';
import cn from 'classnames';
import {
  TextField as MUITextField,
  Autocomplete as MUIAutocomplete,
  type AutocompleteProps as MUIAutocompleteProps,
  type TextFieldProps as MUITextFieldProps,
  type AutocompleteValue as MUIAutocompleteValue,
} from '@mui/material';

import { PartialKeys } from '../../utils/types.utils';

import css from './autocomplete.module.scss';

const rowHeight = 60;
const listHeight = rowHeight * 10;

const LIST_STYLE = {
  maxHeight: `${listHeight}px`,
  height: 'auto',
};

const VOID_MULTIPLE_VALUE = [] as const;

const ListboxVirtualizedAutosized = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(({ children, className, role, ...props }, ref) => {
  const measurerCache = React.useMemo(
    () =>
      new CellMeasurerCache({
        defaultHeight: rowHeight,
        fixedWidth: true,
      }),
    // we need to recalculate sizes by change children
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children]
  );

  const cnListbox = cn(className, css.listbox);
  const rowRenderer: ListRowRenderer = ({ index, style, key, parent }) =>
    Array.isArray(children) && children[index] ? (
      <CellMeasurer
        cache={measurerCache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {React.cloneElement(children[index], { style })}
      </CellMeasurer>
    ) : null;
  const rowCount = Array.isArray(children) ? children.length : 0;

  return (
    <AutoSizer
      className={css.autosizer}
      disableHeight={true}
      style={{ width: '100%' }}
    >
      {({ width }) => (
        <div ref={ref}>
          <div {...props}>
            <List
              autoContainerWidth={true}
              autoWidth={true}
              className={cnListbox}
              deferredMeasurementCache={measurerCache}
              height={listHeight}
              role={role}
              rowCount={rowCount}
              rowHeight={measurerCache.rowHeight}
              rowRenderer={rowRenderer}
              style={LIST_STYLE}
              width={width}
            />
          </div>
        </div>
      )}
    </AutoSizer>
  );
});

export type AutocompleteValue<
  T = string,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> = MUIAutocompleteValue<T, Multiple, DisableClearable, FreeSolo>;

export type AutocompleteProps<
  T = string,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> = PartialKeys<
  MUIAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput'
> &
  Omit<MUITextFieldProps, 'onChange'> & {
    isVirtualized?: boolean;
  };

const Component = <
  T = string,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>({
  label,
  placeholder,
  fullWidth,
  required,
  error,
  InputProps,
  isVirtualized = true,
  options = [],
  value,
  ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const valuePrepared =
    props.multiple && !value
      ? (VOID_MULTIPLE_VALUE as AutocompleteValue<
          T,
          Multiple,
          DisableClearable,
          FreeSolo
        >)
      : value;
  return (
    <MUIAutocomplete<T, Multiple, DisableClearable, FreeSolo>
      ListboxComponent={isVirtualized ? ListboxVirtualizedAutosized : undefined}
      disablePortal={true}
      fullWidth={fullWidth}
      options={options}
      renderInput={(params) => (
        <MUITextField
          {...params}
          {...(InputProps && {
            InputProps: { ...params.InputProps, ...InputProps },
          })}
          className={css.anchor}
          error={error}
          fullWidth={fullWidth}
          label={label}
          placeholder={placeholder}
          required={required}
        />
      )}
      {...props}
      value={valuePrepared}
    />
  );
};

export const Autocomplete = memo(Component) as typeof Component;
