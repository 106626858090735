/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { startOfDay, endOfDay } from 'date-fns';
import { Field } from 'react-final-form';
import { Typography } from '@mui/material';

import { DatePickerField, DatePickerFieldProps } from '../datepicker-field';
import { Language } from '../../application/datepicker/datepicker';
import { useEvent } from '../../utils/react.hooks';

export type DateRangeFieldProps = {
  locale?: Language;
  startLabel?: string;
  endLabel?: string;
  keyFrom?: string;
  keyTo?: string;
  maxDate?: Date;
  minDate?: Date;
  maxDateFrom?: Date;
  maxDateTo?: Date;
  minDateFrom?: Date;
  minDateTo?: Date;
  required?: boolean;
  validate?: (v: any) => string | undefined;
};

export const DateRangeField = React.memo<DateRangeFieldProps>(
  ({
    locale,
    startLabel,
    endLabel,
    keyFrom = 'dateFrom',
    keyTo = 'dateTo',
    minDate,
    maxDate,
    minDateFrom,
    maxDateFrom: maxDateFromDefault = startOfDay(new Date()),
    minDateTo: minDateToDefault,
    maxDateTo = endOfDay(new Date()),
    validate,
    required,
  }) => {
    const [maxDateFrom, setMaxDateFrom] =
      React.useState<Date>(maxDateFromDefault);
    const [minDateTo, setMinDateTo] = React.useState<Date | undefined>(
      minDateToDefault
    );

    const handleChangeFrom = useEvent<
      Required<DatePickerFieldProps>['onChange']
    >((value) => {
      setMinDateTo(value ? endOfDay(value) : minDateToDefault);
    });
    const handleChangeTo = useEvent<Required<DatePickerFieldProps>['onChange']>(
      (value) => {
        setMaxDateFrom(value ? startOfDay(value) : maxDateFromDefault);
      }
    );

    return (
      <React.Fragment>
        <Field<string>
          component={DatePickerField}
          label={startLabel}
          locale={locale}
          maxDate={maxDateFrom}
          minDate={minDateFrom || minDate}
          name={keyFrom}
          required={required}
          validate={validate}
          onChange={handleChangeFrom}
        />
        <Typography> – </Typography>
        <Field<string>
          component={DatePickerField}
          label={endLabel}
          locale={locale}
          maxDate={maxDateTo || maxDate}
          minDate={minDateTo}
          name={keyTo}
          required={required}
          validate={validate}
          onChange={handleChangeTo}
        />
      </React.Fragment>
    );
  }
);
