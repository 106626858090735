import React, { ReactNode, memo } from 'react';
import { Box, IconButton } from '@mui/material';
import { Icon, Icons } from '../../application/icons';
import { useTranslation } from 'react-i18next';
import { CustomDrawer } from '../../application/custom-drawer/custom-drawer';
import css from './sidebar.module.scss';

export type SideBarProps = {
  open?: boolean;
  onChangeOpen?: (value: boolean) => void;
  onLogout: () => void;
  headerMenuItems?: ReactNode;
  contentMenuItems: ReactNode;
  footerMenuItems?: ReactNode;
  Logo?: string;
};
const Component = (props: SideBarProps) => {
  const {
    open,
    onChangeOpen,
    onLogout,
    headerMenuItems,
    contentMenuItems,
    footerMenuItems,
    Logo,
  } = props;
  const { t } = useTranslation();
  const isTogglable = !!onChangeOpen;
  return (
    <CustomDrawer open={open} variant="permanent">
      <Box className={css['drawer-header']}>
        {isTogglable && (
          <>
            <IconButton onClick={() => onChangeOpen(!open)}>
              <Icon className={css['drawer-header__icon']} name="Burger" />
            </IconButton>
            {open ? <img alt="xTrace" src={Logo} /> : null}
          </>
        )}
        {headerMenuItems}
      </Box>
      <Box className={css['drawer-content']}>{contentMenuItems}</Box>
      <Box className={css['drawer-footer']}>
        {footerMenuItems}
        <Box className={css['logout']} onClick={onLogout}>
          <IconButton>
            <Icons.LogoutXt className={css['icon_logout']} />
          </IconButton>
          {open ? t('Выйти') : null}
        </Box>
      </Box>
    </CustomDrawer>
  );
};
export const SideBar = memo(Component) as typeof Component;
