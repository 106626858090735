import React from 'react';

import { Drawer as MuiDrawer, Box, Typography } from '@mui/material';

import { Button } from '../../mui/button';

import css from './index.module.scss';

export type DrawerPropsType = {
  children: React.ReactNode;
  anchor?: 'right' | 'left' | 'top' | 'bottom';
  open: boolean;
  onClose: () => void;
  title: string;
  submitTitle: string;
  cancelTitle: string;
  onSubmit: () => void;
  topActions?: React.ReactNode;
  submitDisabled?: boolean;
};

export const Drawer = ({
  children,
  anchor = 'right',
  open,
  onClose,
  title,
  submitTitle,
  cancelTitle,
  onSubmit,
  topActions,
  submitDisabled = false,
}: DrawerPropsType) => {
  return (
    <MuiDrawer anchor={anchor} open={open} onClose={onClose}>
      <Box className={css.container}>
        <Box className={css.header}>
          <Typography variant="h6">{title}</Typography>
          {topActions}
        </Box>
        <Box className={css.content}>{children}</Box>
        <Box className={css.footer}>
          <Button className={css.cancel} variant="text" onClick={onClose}>
            {cancelTitle}
          </Button>
          <Button disabled={submitDisabled} onClick={onSubmit}>
            {submitTitle}
          </Button>
        </Box>
      </Box>
    </MuiDrawer>
  );
};
