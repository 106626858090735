import React from 'react';

import cn from 'classnames';
import {
  Select as SelectMUI,
  type SelectProps as SelectPropsMUI,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  OutlinedInput,
} from '@mui/material';

import { useEvent } from '../../utils/react.hooks';

import css from './select.module.scss';

export type SelectProps<T extends string | number = string> =
  SelectPropsMUI<T> & {
    options?: { value: T; label: string; optionDisabled?: boolean }[];
    noOptionsText: React.ReactNode;
    hasError?: boolean;
    errorText: string;
  };

export const Select = <T extends string | number = string>({
  disabled,
  required,
  label,
  options,
  fullWidth,
  noOptionsText,
  className,
  name,
  onClose,
  hasError,
  errorText,
  ...selectProps
}: SelectProps<T>) => {
  const handleOnClose = useEvent<Required<SelectProps<T>>['onClose']>((e) => {
    onClose?.(e);
    setTimeout(() => {
      (document.activeElement as HTMLElement)?.blur();
    }, 0);
  });

  return (
    <FormControl className={cn(css.container, className)} fullWidth={fullWidth}>
      <InputLabel disabled={disabled} id={`${name}-label`} required={required}>
        {label}
      </InputLabel>
      <SelectMUI<T>
        disabled={disabled}
        fullWidth={fullWidth}
        input={<OutlinedInput label={label} />}
        labelId={`${name}-label`}
        name={name}
        required={required}
        onClose={handleOnClose}
        {...selectProps}
      >
        {options?.length ? (
          options?.map(({ label, value, optionDisabled }) => (
            // Type cast to string is needed here for use values with generic type <T>
            <MenuItem disabled={optionDisabled} key={`${value}`} value={value}>
              {label}
            </MenuItem>
          ))
        ) : noOptionsText ? (
          <MenuItem>{noOptionsText}</MenuItem>
        ) : null}
      </SelectMUI>
      {hasError && (
        <FormHelperText
          disabled={disabled}
          error={hasError}
          required={required}
        >
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
