import { format } from 'date-fns';

import { Types, types } from '../types';
import { PRODUCT_GROUPS_NAMES } from '../../constants';
import { MARKING_STATUSES } from '../../pages/marking/constants';
import i18n from '../../i18n';
import { ProductGroupTypes } from '../../pages/reports/constants';

type ReportStatusTypes =
  | 'DRAFT'
  | 'READY_TO_SEND'
  | 'PENDING'
  | 'SENT'
  | 'REJECTED';

type ProductType = {
  aggregatedItemsCount: number;
  aggregationType: string;
  aggregationUnitCapacity: number;
  sntins: string[];
  unitSerialNumber: string;
};

type DataType = {
  dateDoc: number;
  isSpdn: boolean;
  participantId: string;
  productionLineId: string;
  productionOrderId: string;
  aggregationUnits: ProductType[];
  aggregatedItemsCount?: string;
  aggregationUnitCapacity?: string;
  unitSerialNumber?: string;
  pg?: ProductGroupTypes;
  sntins?: string[];
};

type AggregationData = {
  createdTimestamp: string;
  json: DataType | null;
  original: DataType | null;
  pg: ProductGroupTypes | null;
  pgName: string;
  products: ProductType[];
  reportId: string;
  status: ReportStatusTypes | null;
  statusName: string;
  type: string;
  updatedTimestamp: string;
  rejectReason?: string;
};

type AggregationResponse = {
  reportId: string;
  status: string;
  type: string;
  pg: ProductGroupTypes;
  createdTimestamp: string;
  json: {
    aggregationUnits: {
      aggregatedItemsCount: number;
      aggregationType: string;
      productionOrderId?: string;
      aggregationUnitCapacity: number;
      sntins: string[];
      unitSerialNumber: string;
    }[];
    dateDoc: number;
    isSpdn: boolean;
    participantId: string;
    productionLineId: string;
  };
};

export type AggregationState = {
  id: null | string;
  item: AggregationData | null;
};

const initialState: AggregationState = {
  id: null,
  item: null,
};

type AggregationActionType = Types[keyof Pick<
  Types,
  | 'AGGREGATION_SET_ITEM'
  | 'SET_AGGREGATION'
  | 'CLEAR_AGGREGATION'
  | 'SET_AGGREGATE'
  | 'CLEAR_AGGREGATE'
>];

type AggregationAction = {
  type: AggregationActionType;
  payload: AggregationResponse;
};

export const reducer = (state = initialState, action: AggregationAction) => {
  switch (action.type) {
    case types.AGGREGATION_SET_ITEM:
      return {
        ...state,
        item: parseItem(action.payload),
      };
    case types.SET_AGGREGATION:
      return {
        ...state,
        ...action.payload,
      };
    case types.CLEAR_AGGREGATION:
      return {
        ...state,
        item: {},
      };
    case types.SET_AGGREGATE:
      return {
        ...state,
        id: action.payload,
      };
    case types.CLEAR_AGGREGATE:
      return {
        ...state,
        id: null,
      };
    default:
      return state;
  }
};

const parseItem = (data: AggregationResponse): AggregationData => {
  const aggregationUnits = data?.json?.aggregationUnits[0] || [];
  return {
    ...data,
    pgName: i18n.t(
      PRODUCT_GROUPS_NAMES[data.pg as keyof typeof PRODUCT_GROUPS_NAMES]
    ),
    statusName: i18n.t(
      MARKING_STATUSES[data.status as keyof typeof MARKING_STATUSES]
    ),
    products: data?.json?.aggregationUnits || [],
    createdTimestamp: format(
      new Date(data.createdTimestamp),
      'dd.MM.yyyy HH:mm'
    ),
    original: {
      pg: data.pg,
      ...data.json,
      unitSerialNumber: String(aggregationUnits?.unitSerialNumber) || '—',
      aggregationUnitCapacity:
        String(aggregationUnits?.aggregationUnitCapacity) || '—',
      aggregatedItemsCount:
        String(aggregationUnits?.aggregatedItemsCount) || '—',
      sntins: aggregationUnits?.sntins || '—',
    },
  };
};
