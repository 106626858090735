import { Types, types } from '../types';

type ParticipantsActionType = Types[keyof Pick<
  Types,
  | 'PARTICIPANT_SET_PROFILE'
  | 'PARTICIPANT_CLEAR_PROFILE'
  | 'PARTICIPANT_SET_DISTRIBUTION'
>];

type ParticipantData = {
  tin: string;
  omsId: string;
  address: string;
  contactPerson: string;
  distributionValue: string;
  name: string;
  distribution: boolean;
};

export type ParticipantState = {
  item: ParticipantData;
};

const initialState: ParticipantState = {
  item: {
    tin: '',
    omsId: '',
    address: '',
    contactPerson: '',
    distributionValue: '',
    name: '',
    distribution: false,
  },
};

type ParticipantsAction = {
  type: ParticipantsActionType;
  payload: ParticipantData;
};

export const reducer = (state = initialState, action: ParticipantsAction) => {
  switch (action.type) {
    case types.PARTICIPANT_SET_PROFILE:
      return {
        ...state,
        item: {
          ...action.payload,
          tin: `${action.payload.tin}`,
          distributionValue: action.payload.distribution ? 'Да' : 'Нет',
        },
      };
    case types.PARTICIPANT_CLEAR_PROFILE:
      return {
        ...state,
        item: {},
      };
    case types.PARTICIPANT_SET_DISTRIBUTION:
      return {
        ...state,
        item: {
          ...state.item,
          distributionValue: action.payload.distribution ? 'Да' : 'Нет',
          distribution: action.payload.distribution,
        },
      };
    default:
      return state;
  }
};
