import React from 'react';

export const getTextFromReactNode = (
  node: React.ReactNode | React.ReactNode[]
): string =>
  React.Children.toArray(node)
    .map((child) =>
      typeof child === 'string' || typeof child === 'number'
        ? `${child}`
        : React.isValidElement(child)
        ? getTextFromReactNode(child.props.children)
        : ''
    )
    .join('');

export const cancelEvent = <T>(e: React.SyntheticEvent<T>) => {
  e.preventDefault();
  e.stopPropagation();
};
