import React from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';

import styles from './index.module.scss';

export type NotFoundPageProps = {
  redirectLink: string;
  contentText?: string;
  headerText?: string;
  linkText?: string;
  code?: string;
};

export const NotFoundPage: React.FC<NotFoundPageProps> = ({
  redirectLink,
  headerText = 'Страница не найдена',
  linkText = 'Перейти на главную',
  contentText = 'К сожалению, запрошенной вами страницы не существует.',
  code = '404',
}) => {
  return (
    <div className={styles.errorWrapper}>
      <div className={styles.line} />
      <div className={styles.stroke}>
        <h2>{code}</h2>
        <h3>{headerText}</h3>
      </div>
      <p>{contentText}</p>
      <Link
        className={styles.transfer}
        component={RouterLink}
        to={redirectLink}
      >
        {linkText}
      </Link>
    </div>
  );
};
