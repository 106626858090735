import React, { memo } from 'react';

import { Box, Stack } from '@mui/material';
import { Field } from 'react-final-form';

import { Language } from '../../../../application/datepicker/datepicker';
import {
  SelectField,
  type SelectFieldProps,
} from '../../../../final-form/select-field';
import {
  AutocompleteField,
  type AutocompleteFieldProps,
  type AutocompleteFieldValue,
} from '../../../../final-form/autocomplete-field';
import {
  CheckboxField,
  type CheckboxFieldProps,
} from '../../../../final-form/checkbox-field';
import {
  AutocompleteAsyncField,
  type AutocompleteAsyncFieldProps,
  type AutocompleteAsyncFieldValue,
} from '../../../../final-form/autocomplete-async-field';
import { cancelEvent } from '../../../../utils/react.utils';
import {
  DateRangeField,
  DateRangeFieldProps,
} from '../../../../final-form/daterange-field/daterange-field';
import {
  TextMaskedFormField,
  TextMaskedFormFieldProps,
} from '../../../../final-form/text-masked-field';

import styles from '../filters-table.module.scss';

export enum FilterType {
  CHECKBOX = 'checkbox',
  TEXT = 'text',
  SELECT = 'select',
  DATE_RANGE = 'date-range',
  AUTOCOMPLETE = 'autocomplete',
  AUTOCOMPLETE_FREESOLO = 'autocomplete-freesolo',
  AUTOCOMPLETE_SELECT = 'autocomplete-select',
  AUTOCOMPLETE_MULTIPLE = 'autocomplete-multiple',
  AUTOCOMPLETE_ASYNC = 'autocomplete-async',
  AUTOCOMPLETE_ASYNC_MULTIPLE = 'autocomplete-async-multiple',
  AUTOCOMPLETE_ASYNC_FREESOLO = 'autocomplete-async-freesolo',
}

export type FieldByTypeProps = {
  id: string;
  label: string;
} & (
  | {
      type: FilterType.TEXT;
      config?: Partial<TextMaskedFormFieldProps>;
    }
  | {
      type: FilterType.CHECKBOX;
      config?: Partial<CheckboxFieldProps>;
    }
  | {
      type: FilterType.SELECT;
      config?: Partial<SelectFieldProps>;
    }
  | {
      type: FilterType.AUTOCOMPLETE;
      config?: Partial<AutocompleteFieldProps<string>>;
    }
  | {
      type: FilterType.AUTOCOMPLETE_FREESOLO;
      config: Partial<AutocompleteFieldProps<string, false, false, true>>;
    }
  | {
      type: FilterType.AUTOCOMPLETE_SELECT;
      config?: Partial<AutocompleteFieldProps<string>>;
    }
  | {
      type: FilterType.AUTOCOMPLETE_MULTIPLE;
      config?: Partial<AutocompleteFieldProps<string, true>>;
    }
  | {
      type: FilterType.AUTOCOMPLETE_ASYNC;
      config?: Partial<AutocompleteAsyncFieldProps<string>>;
    }
  | {
      type: FilterType.AUTOCOMPLETE_ASYNC_MULTIPLE;
      config?: Partial<AutocompleteAsyncFieldProps<string, true>>;
    }
  | {
      type: FilterType.AUTOCOMPLETE_ASYNC_FREESOLO;
      config: Partial<AutocompleteAsyncFieldProps<string, false, false, true>>;
    }
  | {
      type: FilterType.DATE_RANGE;
      config: DateRangeFieldProps;
    }
);

const isAutocompleteOptionEqualToValue = (
  option: AutocompleteFieldValue,
  value: AutocompleteFieldValue
) => value && option.value === value.value;

type FilterByTypeProps = FieldByTypeProps & { locale: Language };

export const FilterByType = memo<FilterByTypeProps>(
  ({ label, id: name, config = {}, type, locale }) => {
    const commonProps = { label, name, ...config };

    const getFilterField = () => {
      switch (type) {
        case FilterType.TEXT:
          return (
            <Field<string>
              fullWidth
              disableClearable={false}
              {...commonProps}
              component={TextMaskedFormField}
            />
          );
        case FilterType.SELECT:
          return (
            <Field<string>
              fullWidth
              {...commonProps}
              component={SelectField<string>}
            />
          );
        case FilterType.AUTOCOMPLETE_SELECT:
          return (
            <Field<AutocompleteFieldValue<string>>
              fullWidth
              isOptionEqualToValue={isAutocompleteOptionEqualToValue}
              {...commonProps}
              InputProps={{ onKeyDown: cancelEvent }}
              component={AutocompleteField<string>}
            />
          );
        case FilterType.AUTOCOMPLETE:
          return (
            <Field<AutocompleteFieldValue<string>>
              fullWidth
              isOptionEqualToValue={isAutocompleteOptionEqualToValue}
              {...commonProps}
              component={AutocompleteField<string>}
            />
          );
        case FilterType.AUTOCOMPLETE_FREESOLO:
          return (
            <Field<AutocompleteFieldValue<string>>
              autoSelect
              fullWidth
              isOptionEqualToValue={isAutocompleteOptionEqualToValue}
              {...commonProps}
              freeSolo
              component={AutocompleteField<string, false, false, true>}
            />
          );
        case FilterType.AUTOCOMPLETE_MULTIPLE:
          return (
            <Field<AutocompleteFieldValue<string>[]>
              fullWidth
              isOptionEqualToValue={isAutocompleteOptionEqualToValue}
              {...commonProps}
              component={AutocompleteField<string, true>}
              multiple={true}
            />
          );
        case FilterType.AUTOCOMPLETE_ASYNC:
          return (
            <Field<AutocompleteAsyncFieldValue<string>>
              fullWidth
              isOptionEqualToValue={isAutocompleteOptionEqualToValue}
              {...commonProps}
              component={AutocompleteAsyncField<string>}
            />
          );
        case FilterType.AUTOCOMPLETE_ASYNC_MULTIPLE:
          return (
            <Field<AutocompleteAsyncFieldValue<string>[]>
              fullWidth
              isOptionEqualToValue={isAutocompleteOptionEqualToValue}
              {...commonProps}
              component={AutocompleteAsyncField<string, true>}
              multiple={true}
            />
          );
        case FilterType.AUTOCOMPLETE_ASYNC_FREESOLO:
          return (
            <Field<AutocompleteAsyncFieldValue<string>>
              autoSelect
              fullWidth
              isOptionEqualToValue={isAutocompleteOptionEqualToValue}
              {...commonProps}
              freeSolo
              component={AutocompleteAsyncField<string, false, false, true>}
            />
          );
        case FilterType.CHECKBOX:
          return (
            <Field<boolean>
              {...commonProps}
              component={CheckboxField}
              type="checkbox"
            />
          );
        case FilterType.DATE_RANGE:
          return (
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={2}
            >
              <DateRangeField {...config} locale={locale} />
            </Stack>
          );
        default:
          return <h1>no field for {type}</h1>;
      }
    };

    return <Box className={styles.filterField}>{getFilterField()}</Box>;
  }
);
