import { put } from 'redux-saga/effects';

import { setIsLoading } from '../actions/common';
import { API } from '../../api';
import { showErrorToast } from '../../components/toastWrapper';
import { setItem } from '../actions/rejections';
import { history } from '../../store';
import { routes } from '../../routes';

export function* loadRejectionItemSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.reports.item(action.payload);
    yield put(setItem({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* createRejectionsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.reports.dropout(payload.data, payload.query);
    yield put(setIsLoading(false));
    history.push(routes.documents());
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* updateDropoutSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.reports.dropoutUpdate(
      payload.data,
      payload.query,
      payload.reportId
    );
    yield put(setIsLoading(false));
    history.push(routes.documents());
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}
