import React, { memo, useMemo, useRef, useState } from 'react';

import { CircularProgress, useEventCallback } from '@mui/material';
import { debounce } from 'lodash/fp';

import { Autocomplete, type AutocompleteProps } from '../autocomplete';
import { PartialKeys } from '../../utils/types.utils';

import css from './autocomplete-async.module.scss';

const SPINNER = <CircularProgress className={css.spinner} size={'1rem'} />;
const VOID_OPTIONS = [] as const;
const checkCanRequestByMinLength =
  (minLength: number) => (inputValue: string) =>
    inputValue.length >= minLength;

export type AutocompleteAsyncProps<
  T = string,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> = PartialKeys<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'options'
> & {
  requestDebounce?: number;
  minLengthForRequest?: number;
  request?: (inputValue: string, signal?: AbortSignal) => Promise<T[]>;
  checkCanRequest?: (inputValue: string) => boolean;
  prepareRequestValue?: (inputValue: string) => string;
};

const Component = <
  T = string,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>(
  props: AutocompleteAsyncProps<T, Multiple, DisableClearable, FreeSolo>
) => {
  const {
    requestDebounce = 600,
    value,
    minLengthForRequest = 2,
    onClose,
    onInputChange,
    InputProps,
    prepareRequestValue = (inputValue: string) => inputValue.trim(),
    checkCanRequest = checkCanRequestByMinLength(minLengthForRequest),
    request,
    options: defaultOptions = VOID_OPTIONS,
    size = 'small',
    ...autocompleteProps
  } = props;
  type Props = Required<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
  >;
  const [options, setOptions] = useState<readonly T[]>(defaultOptions);
  const [isLoading, setIsLoading] = useState<boolean>();
  const abortControllerRef = useRef<AbortController>();

  const handleClose: Props['onClose'] = useEventCallback((event, reason) => {
    abortControllerRef.current?.abort();
    onClose?.(event, reason);
  });

  React.useEffect(() => setOptions(defaultOptions), [defaultOptions]);

  const handleInputChange: Props['onInputChange'] = useMemo(() => {
    const requestAborted: Props['onInputChange'] = async (
      event,
      value,
      reason
    ) => {
      onInputChange?.(event, value, reason);
      if (['input', 'clear'].includes(reason)) {
        abortControllerRef.current?.abort();
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        if (request && checkCanRequest(value)) {
          try {
            setIsLoading(true);
            const options = await request(
              prepareRequestValue(value),
              abortController.signal
            );
            setOptions(options);
            setIsLoading(false);
          } catch (error) {
            console.error(error);
            setIsLoading(false);
          }
        }
      }
    };
    return debounce(requestDebounce)(requestAborted);
  }, [
    checkCanRequest,
    onInputChange,
    prepareRequestValue,
    request,
    requestDebounce,
  ]);

  const InputPropsWithSpinner = useMemo<Props['InputProps']>(
    () => ({
      ...InputProps,
      ...(isLoading && { endAdornment: SPINNER }),
    }),
    [InputProps, isLoading]
  );

  return (
    <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
      {...autocompleteProps}
      InputProps={InputPropsWithSpinner}
      loading={isLoading}
      options={options}
      size={size}
      value={value}
      onClose={handleClose}
      onInputChange={handleInputChange}
    />
  );
};

export const AutocompleteAsync = memo(Component) as typeof Component;
