import fp from 'lodash/fp';

import { showError, showSuccess } from '@ibox/ui';

import { generateGuid } from '../../helpers';

const parseErrors = (payload: any) => {
  const globalErrors = fp.pipe(
    fp.get('response.data.globalErrors'),
    fp.map((item: any) => ({ id: generateGuid(), name: item.error }))
  )(payload);
  const errDesc = fp.pipe(fp.get('response.data.description'))(payload);
  const err = fp.pipe(fp.get('response.data.error'))(payload);
  const commonErrors = [];
  if (err) {
    commonErrors.push({ id: generateGuid(), name: `${err}` });
  }
  if (errDesc) {
    commonErrors.push({ id: generateGuid(), name: `${errDesc}` });
  }
  return [...globalErrors, ...commonErrors];
};

export const showErrorToast = (props: any) => showError(parseErrors, props);
export const showSuccessToast = (props: any) => showSuccess(props);
