import React from 'react';

import cn from 'classnames';
import { FieldRenderProps } from 'react-final-form';

import { DatePicker, DatePickerProps } from '../../application/datepicker';
import { useEvent } from '../../utils/react.hooks';

import css from './datepicker-field.module.scss';

export type DatePickerFieldProps = DatePickerProps &
  FieldRenderProps<string | undefined>;

export const DatePickerField = React.memo<DatePickerFieldProps>(
  ({
    input: {
      name,
      value,
      onChange: onChangeField,
      onBlur: onBlurField,
      onFocus: onFocusField,
    },
    dateFormat = 'dd.MM.yyyy',
    disabled,
    meta,
    required,
    className,
    onChange,
    onBlur,
    onFocus,
    ...props
  }) => {
    const isVisibleError = !disabled && meta.modified;
    const hasError = isVisibleError && Boolean(meta.error);

    type Props = Required<DatePickerFieldProps>;

    const handleBlur = useEvent<Props['onBlur']>((event) => {
      onBlurField(event);
      onBlur?.(event);
    });

    const handleChange = useEvent<Props['onChange']>((date, event) => {
      onChangeField(date?.toISOString() ?? null);
      onChange?.(date, event);
    });

    const handleFocus = useEvent<Props['onFocus']>((event) => {
      onFocusField(event);
      onFocus?.(event);
    });

    // This effect need for handle change event by change value externally (from out of component, f.e. via final-form)
    React.useEffect(() => {
      onChange?.(value ? new Date(value) : null, undefined);
    }, [onChange, value]);

    return (
      <DatePicker
        {...props}
        className={cn(className, {
          [css.fieldRequiredError]: (!value && required) || hasError,
          [css.disabled]: disabled,
          [css.hasError]: hasError,
        })}
        dateFormat={dateFormat}
        disabled={disabled}
        error={hasError}
        helperText={isVisibleError && meta.error}
        name={name}
        required={required}
        selected={value ? new Date(value) : null}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
      />
    );
  }
);
