export const hyPalette = {
  colorSchemes: {
    light: {
      palette: {
        type: 'light',
        primary: {
          main: 'rgb(43, 93, 128)',
          contrast: '#ffffff',
        },
        secondary: {
          main: '#3487c2',
        },
        error: {
          main: '#d32f2f',
          800: 'rgba(211, 47, 47, 0.08)',
        },
        info: {
          main: '#0057b2',
        },
        success: {
          main: '#689f38',
        },
        text: {
          secondary: 'rgba(0,0,0,0.6)',
        },
        action: {
          hover:
            'rgba(var(--mui-palette-action-activeChannel), var(--mui-palette-action-hoverOpacity))',
          selected:
            'rgba(var(--mui-palette-action-selectedChannel), var(--mui-palette-action-selectedOpacity))',
          disabledBackground:
            'rgba(var(--mui-palette-action-activeChannel), 0.12)',
          focus:
            'rgba(var(--mui-palette-action-activeChannel), var(--mui-palette-action-focusOpacity))',
          disabled:
            'rgba(var(--mui-palette-action-activeChannel), var(--mui-palette-action-disabledOpacity))',
          active: 'rgba(var(--mui-palette-action-activeChannel), 0.56)',
          hoverOpacity: 0.04,
          selectedOpacity: 0.08,
          disabledOpacity: 0.38,
          focusOpacity: 0.12,
          activeChannel: '0,0,0',
          selectedChannel: '0,0,0',
        },
      },
    },
  },
};
