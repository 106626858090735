import React from 'react';

import cn from 'classnames';
import { Chip, ChipProps } from '@mui/material';

import styles from './chip-status.module.scss';

export type ChipStatusProps = ChipProps & { small?: boolean };

export type ChipStatusColor = ChipStatusProps['color'];

export const ChipStatus = React.memo<ChipStatusProps>(
  ({ className, small, ...props }) => (
    <Chip
      className={cn(styles.container, className, {
        [styles.container_small]: !!small,
      })}
      data-testid="ChipStatus"
      {...props}
    />
  )
);
