import React, { memo } from 'react';

import cn from 'classnames';
import { MenuItem, Select, type SelectProps } from '@mui/material';

import { type Language } from '..';

import css from './language-switcher.module.scss';

export type LanguageSwitcherProps<T extends Language = Language> = {
  className?: string;
  languages: T[];
  selected: T;
  onChange: (language: T) => void;
};

const Component = <T extends Language = Language>({
  className,
  languages,
  selected,
  onChange,
}: LanguageSwitcherProps<T>) => {
  type Props = Required<SelectProps<T>>;
  const handleChange: Props['onChange'] = (event) => {
    onChange(event.target.value as T);
  };

  return (
    <Select<T>
      className={cn(css.container, className)}
      data-testid="LanguageSwitcher"
      defaultValue={selected}
      onChange={handleChange}
    >
      {languages.map((language) => (
        <MenuItem className={css['menu-item']} key={language} value={language}>
          {language}
        </MenuItem>
      ))}
    </Select>
  );
};

export const LanguageSwitcher = memo(Component) as typeof Component;
