import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash/fp';
import { Box, Typography, IconButton, Grid } from '@mui/material';
import { format } from 'date-fns';

import { Icon } from '../../../application/icons';
import { ChipStatus } from '../../../application/chip-status';
import { Button } from '../../../mui/button';
import { type Filter, type FilterSet } from '../filters/filters-table';
import { isIsoDate } from '../../../utils/helpers';

import styles from './history.module.scss';

type HistoryProps = {
  isHistory: boolean;
  filters: FilterSet[];
  noFilterText: string;
  applyFilters: (filters: Filter[]) => void;
  onSave?: (filters: Filter[]) => void;
  onEdit?: (filter: FilterSet) => void;
  onDelete?: (filterId: string) => void;
};

export const renderFilterChip = ({
  filter,
  onDelete,
  size,
  disabled,
}: {
  filter: Filter;
  onDelete?: (id: string) => void;
  size?: 'small';
  disabled?: boolean;
}) => {
  const value =
    filter.filterValue && isIsoDate(filter.filterValue)
      ? format(new Date(filter.filterValue), 'dd-MM-yyyy')
      : filter.filterValue;
  return (
    <Grid item key={`${filter.id}${value}`}>
      <ChipStatus
        color="info"
        disabled={disabled}
        label={[filter.filterLabel, value].filter(Boolean).join(': ')}
        size={size}
        variant="outlined"
        onDelete={onDelete ? () => onDelete?.(filter.id) : undefined}
      />
    </Grid>
  );
};

export const History = (props: HistoryProps): JSX.Element => {
  const { isHistory, filters, noFilterText, applyFilters, onDelete, onEdit } =
    props;

  const { t } = useTranslation();

  const [filtersForRender, setFiltersForRender] = useState<FilterSet[]>([]);

  useEffect(() => {
    if (filters.length) {
      if (isHistory) {
        const sortedFilters = sortBy('date', filters).reverse().slice(0, 5);
        setFiltersForRender(sortedFilters);
      } else {
        setFiltersForRender(filters);
      }
    }
  }, [filters, isHistory]);

  return (
    <>
      {filters.length ? (
        <>
          {filtersForRender.map((filter) => (
            <Box className={styles.historyGrid} key={filter.id}>
              <Typography className={styles.name} fontSize={12}>
                {filter.name}
              </Typography>
              <div className={styles.filters}>
                {filter.filters.map((filter) =>
                  renderFilterChip({ filter, size: 'small' })
                )}
              </div>
              <Box className={styles.filterHistoryActions}>
                {!isHistory && (
                  <>
                    <IconButton
                      color="primary"
                      onClick={() => onEdit?.(filter)}
                    >
                      <Icon name="Edit" size="Medium" />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => onDelete?.(filter.id)}
                    >
                      <Icon name="DeleteFill" />
                    </IconButton>
                  </>
                )}
                <Button onClick={() => applyFilters(filter.filters)}>
                  {t('Применить')}
                </Button>
              </Box>
            </Box>
          ))}
        </>
      ) : (
        <Box className={styles.emptyFilters}>
          <Typography minWidth="10%">{noFilterText}</Typography>
        </Box>
      )}
    </>
  );
};
