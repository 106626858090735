import { useMemo, useState, useLayoutEffect } from 'react';

import fp from 'lodash/fp';
import { useHistory } from 'react-router';
import { default as queryString } from 'query-string';

import { useCheckIsMounted } from '../utils';

import { FormValues } from './components/filters/filters-table';

import {
  type Filter,
  type FiltersTableProps,
  type FiltersQueryArgs,
  type TableProps,
} from './';

type TableQueryArgs = {
  orderBy?: string;
  orderDirection?: 'ASC' | 'DESC';
  sortLanguage?: string;
  limit?: number;
  offset?: number;
};

type QueryArgs<T extends Record<string, unknown> = FiltersQueryArgs> =
  TableQueryArgs & T;

type QueryUniqArgs<T extends Record<string, unknown> = FiltersQueryArgs> =
  QueryArgs<T> & { uid?: string };

export const TABLE_QUERY_ARGS_DEFAULT = {
  limit: 10,
};

type UseTableArgs<T extends Record<string, unknown> = FiltersQueryArgs> = {
  sortLanguage: string;
  queryArgsDefault?: QueryArgs<T>;
  emptyData: () => void;
  setInitialFilters: (filters: FormValues) => void;
};

type UseTable<T extends Record<string, unknown> = Record<string, unknown>> = {
  queryArgs: QueryArgs<T>;
  tableState: TableProps['state'];
  handleSortingChange: TableProps['onSortingChange'];
  handlePageChange: TableProps['onPageChange'];
  handleFiltersApply: FiltersTableProps['onApplyFilters'];
};

const prepareQueryArgs = fp.omit(['limit', 'offset']);

export const useTable = <
  T extends Record<string, unknown> = Record<string, unknown>
>({
  sortLanguage,
  queryArgsDefault,
  emptyData,
  setInitialFilters,
}: UseTableArgs<T>): UseTable<T> => {
  const history = useHistory();
  const checkIsMounted = useCheckIsMounted();

  const queryArgsFromLocation = prepareQueryArgs(
    queryString.parse(location.search)
  );

  const initialTableState: Required<TableProps>['state'] = useMemo(() => {
    const { orderBy, orderDirection } = queryArgsFromLocation;
    const id =
      typeof orderBy === 'string' ? `${orderBy}`.toLowerCase() : undefined;
    const desc =
      typeof orderDirection === 'string' ? orderDirection === 'DESC' : false;
    return id === undefined ? {} : { sorting: [{ id, desc }] };
  }, [queryArgsFromLocation]);

  const [tableState, setTableState] =
    useState<Required<TableProps>['state']>(initialTableState);

  const [queryArgs, setQueryArgs] = useState<QueryUniqArgs<T>>({
    ...(TABLE_QUERY_ARGS_DEFAULT as QueryUniqArgs<T>),
    ...queryArgsDefault,
    ...queryArgsFromLocation,
  });

  const updateQueryArgs = (queryArgs: QueryUniqArgs<T>) => {
    const newQueryArgs = {
      ...queryArgsDefault,
      ...queryArgs,
    };
    const search = queryString.stringify(prepareQueryArgs(newQueryArgs));
    history.replace({ ...history.location, search });
    setQueryArgs(newQueryArgs);
  };

  useLayoutEffect(() => {
    emptyData();
    if (checkIsMounted()) {
      updateQueryArgs({
        ...(TABLE_QUERY_ARGS_DEFAULT as QueryUniqArgs<T>),
        offset: 0,
      });
      setInitialFilters({});
      setTableState({});
    }
    // We need to update queryArgs only by change queryArgsDefault
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryArgsDefault]);

  const handleSortingChange: TableProps['onSortingChange'] = (fn) => {
    const [columnSort] = typeof fn === 'function' ? fn([]) : fn;
    if (columnSort !== undefined) {
      const { id } = columnSort;
      const desc = !(queryArgs.orderDirection === 'DESC');
      emptyData();
      setTableState((tableState) => ({
        ...tableState,
        sorting: [{ id, desc }],
      }));
      updateQueryArgs({
        ...queryArgs,
        offset: 0,
        orderBy: id.toUpperCase(),
        orderDirection: desc ? 'DESC' : 'ASC',
        sortLanguage,
      });
    }
  };

  const handlePageChange: TableProps['onPageChange'] = () =>
    updateQueryArgs({
      ...queryArgs,
      offset: (queryArgs.offset ?? 0) + (queryArgs.limit ?? 0),
    });

  const handleFiltersApply = (filters: Filter[]) => {
    emptyData();
    updateQueryArgs({
      ...queryArgsDefault,
      ...filters.reduce(
        (acc, { id, value }) => ({ ...acc, [id]: value }),
        fp.pick(['orderBy', 'orderDirection', 'sortLanguage', 'limit'])(
          queryArgs
        )
      ),
      offset: 0,
    } as QueryUniqArgs<T>);
  };

  return {
    queryArgs,
    tableState,
    handleSortingChange,
    handlePageChange,
    handleFiltersApply,
  };
};
