import React from 'react';

import { AnyMaskedOptions } from 'imask';
import { IMaskMixin } from 'react-imask';
import { type IMaskMixinProps } from 'react-imask/esm/mixin';

import { TextField, TextFieldProps } from '../textfield/textfield';

const TextFieldMaskedRaw = IMaskMixin<
  AnyMaskedOptions,
  false,
  string,
  HTMLInputElement,
  TextFieldProps
>(({ inputRef, ...props }) => <TextField {...props} inputRef={inputRef} />);

export type TextFieldMaskedProps = TextFieldProps &
  IMaskMixinProps<AnyMaskedOptions, false, string, HTMLInputElement> & {
    ref: React.ForwardedRef<HTMLInputElement>;
  };

export const TextFieldMasked = React.forwardRef<
  HTMLInputElement,
  TextFieldMaskedProps
>(({ ref: _, ...props }, ref) => (
  <TextFieldMaskedRaw inputRef={ref} {...props} />
));

export const TextFieldMaskedDate = React.forwardRef<
  HTMLInputElement,
  TextFieldMaskedProps
>(({ ref: _, ...props }, ref) => (
  <TextFieldMasked inputRef={ref} {...props} mask={Date} />
));

export const TextFieldMaskedNumber = React.forwardRef<
  HTMLInputElement,
  TextFieldMaskedProps
>(({ ref: _, ...props }, ref) => (
  <TextFieldMasked inputRef={ref} {...props} mask={Number} />
));

export const TextFieldMaskedDigit = React.forwardRef<
  HTMLInputElement,
  TextFieldMaskedProps
>(({ ref: _, ...props }, ref) => (
  <TextFieldMasked inputRef={ref} {...props} mask={/^[0-9]*$/} />
));

export const TextFieldMaskedInn = React.forwardRef<
  HTMLInputElement,
  TextFieldMaskedProps
>(({ ref: _, ...props }, ref) => (
  <TextFieldMasked inputRef={ref} {...props} mask="00000000" />
));
