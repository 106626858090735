import React from 'react';

import { Translation, Trans } from 'react-i18next';

export const Translate = (text: string) => {
  const preparedText = text ? text.replace(/:/g, '**') : '';
  return (
    <Translation>{(t) => <Trans t={t}>{preparedText}</Trans>}</Translation>
  );
};
