import React from 'react';

import cn from 'classnames';

import css from './index.module.scss';

export type ButtonActionProps = {
  className?: string;
  type?: 'CRITICAL';
  children?: React.ReactNode;
  Icon?: React.ReactNode;
  onClick?: VoidFunction;
};

export const ButtonAction = React.memo<ButtonActionProps>(
  ({ onClick, Icon, children, type, className }) => (
    <button
      className={cn(
        css.container,
        className,
        type === 'CRITICAL' && css.critical
      )}
      data-testid="ButtonAction"
      type="button"
      onClick={onClick}
    >
      {Icon}
      <span className="text">{children}</span>
    </button>
  )
);
