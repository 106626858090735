import { parse as parseContentDisposition } from 'content-disposition';
import { saveAs } from 'file-saver';

export const saveFileFromResponse = async (
  response: Response
): Promise<Response> => {
  const contentDispositionRaw =
    response.headers.get('content-disposition') || '';
  const contentDisposition = parseContentDisposition(contentDispositionRaw);
  const blob = await response.blob();
  saveAs(blob, contentDisposition?.parameters?.filename || 'file.unknown');
  return response;
};
