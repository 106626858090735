import React from 'react';

import { Box, BoxProps } from '@mui/material';

import styles from './common-styles.module.scss';

export function MainMenu({ children, ...boxProps }: BoxProps) {
  return (
    <Box className={styles.MainMenuRoot} {...boxProps}>
      <Box className={styles.AppMainMenuItems}>{children}</Box>
    </Box>
  );
}
