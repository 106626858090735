import React, {
  useRef,
  useEffect,
  useLayoutEffect,
  useCallback,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';

/**
 * Same as React's useCallback, but returns a stable reference.
 * This library is a user-land implementation of the useEvent hook, proposed in this [RFC](https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md).
 **/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useEvent<T extends (...args: any[]) => any>(handler: T) {
  const handlerRef = useRef<T | null>(handler);

  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback((...args: Parameters<T>) => {
    const fn = handlerRef.current;
    return fn?.(...args);
  }, []);
}

/**
 * A simple useState hook that updates a boolean with togglers and dispatcher.
 * @returns {Array<boolean, SetStateAction>} array of:  [isFlag, setIsFlagTrue, setIsFlagFalse, toggleIsFlag, setIsFlag]
 */

export function useFlag(
  defaultValue = false
): [
  boolean,
  VoidFunction,
  VoidFunction,
  VoidFunction,
  Dispatch<SetStateAction<boolean>>
] {
  const [isFlag, setIsFlag] = useState(defaultValue);
  const setIsFlagTrue = useCallback(() => setIsFlag(true), []);

  const setIsFlagFalse = useCallback(() => setIsFlag(false), []);
  const toggleIsFlag = useCallback(() => setIsFlag((value) => !value), []);

  return [isFlag, setIsFlagTrue, setIsFlagFalse, toggleIsFlag, setIsFlag];
}

export function useCheckHasAutofill(): [
  boolean | undefined,
  (e: HTMLElement | React.SyntheticEvent<HTMLElement>) => void
] {
  const [hasAutofill, setHasAutofill] = useState<boolean>();
  const checkHasAutofill = useEvent(
    (e: HTMLElement | React.SyntheticEvent<HTMLElement>) => {
      const container = 'currentTarget' in e ? e.currentTarget : e;
      setHasAutofill(!!container.querySelector('input:autofill'));
    }
  );
  return [hasAutofill, checkHasAutofill];
}

const isRefObject = <T>(ref: React.Ref<T>): ref is React.RefObject<T> => {
  return ref !== null && typeof ref === 'object' && 'current' in ref;
};

export const fillRef =
  <T>(ref: React.Ref<T>) =>
  (node: T) => {
    if (typeof ref === 'function') {
      ref(node);
    } else if (isRefObject(ref)) {
      (ref as React.MutableRefObject<T | null>).current = node;
    }
  };

export const composeRef =
  <T>(...refs: (React.Ref<T> | null | undefined)[]): React.Ref<T> =>
  (node: T) => {
    refs.forEach((ref) => {
      ref && fillRef(ref)(node);
    });
  };

/**
 * Custom hook for determining if the component is currently mounted.
 * Use checkIsCMounted() to check if the component is currently mounted before performing certain actions.
 * @returns {() => boolean} A function that returns a boolean value indicating whether the component is mounted.
 * @see [Documentation](https://usehooks-ts.com/react-hook/use-is-mounted)
 * @example
 * const checkIsCMounted = useCheckIsMounted();
 * if (checkIsCMounted()) {
 *  // do some here
 * }
 */

export function useCheckIsMounted(): () => boolean {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}
