import React, { FC } from 'react';

import StepperMui from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import cn from 'classnames';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from './stepper.module.scss';

type Step = {
  disableBack?: boolean;
  verified?: boolean;
  disabled: boolean;
  label: string;
  step: number;
};

export type StepperProps = {
  currentStep: number;
  className?: string;
  steps: Step[];
  onStepClick: (v: Step) => void;
};

export const Stepper: FC<StepperProps> = ({
  currentStep,
  steps,
  onStepClick,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <Box className={cn(styles.stepsContainer, className)}>
      <StepperMui
        activeStep={currentStep}
        className={styles.Stepper}
        orientation="vertical"
      >
        {steps.map((step, index) => {
          const isCurrentStep = index === currentStep;
          const onStepClickHandler = () => {
            !isCurrentStep && !step.disabled && onStepClick(step);
          };
          return (
            <Step
              completed={step.verified}
              disabled={step.disabled}
              key={step.label}
            >
              <StepLabel onClick={onStepClickHandler}>
                {t(step.label)}
              </StepLabel>
            </Step>
          );
        })}
      </StepperMui>
    </Box>
  );
};
