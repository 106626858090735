import React, { memo, useMemo, FC } from 'react';

import { Grid } from '@mui/material';

import { CommonDialog } from '../dialog';

export type ConfirmProps = {
  labelSubmit: string;
  labelCancel: string;
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  children?: React.ReactNode;
  content?: React.ReactNode | string;
  title: string;
  isLoading?: boolean;
  isCustomContent?: boolean;
  className?: string;
};

export const Confirm: FC<ConfirmProps> = memo(
  ({
    onClose,
    open,
    labelSubmit,
    labelCancel,
    title,
    onSubmit,
    content,
    children,
    isLoading,
    isCustomContent,
    className,
  }) => {
    const renderedContent = useMemo(() => {
      if (isCustomContent || typeof content !== 'string') {
        return content;
      }
      return content;
    }, [isCustomContent, content]);

    return (
      <CommonDialog
        data-testid="Confirm"
        className={className}
        isLoading={isLoading}
        labelCancel={labelCancel}
        labelSubmit={labelSubmit}
        open={open}
        size="sm"
        title={title}
        onClose={onClose}
        onSubmit={onSubmit}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {renderedContent}
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </CommonDialog>
    );
  }
);
