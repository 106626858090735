import { put, call } from 'redux-saga/effects';

import { setIsLoading } from '../actions/common';
import { API } from '../../api';
import { setList, setListForConnection } from '../actions/serviceProviders';
import {
  showErrorToast,
  showSuccessToast,
} from '../../components/toastWrapper';

import { getPID } from './participants';

export function* loadServiceProvidersSaga() {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.participants.sites();
    yield put(setList({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* loadServiceProvidersForConnectionSaga() {
  try {
    // eslint-disable-next-line no-unused-vars
    yield put(setIsLoading(true));
    const json = yield API.rest.serviceProviders.list({
      page: 1,
      size: 100000,
    });
    yield put(setListForConnection({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* deleteAllSPConnectionsSaga({ payload }) {
  try {
    const { list, onFinish } = payload;
    yield put(setIsLoading(true));
    for (let i = 0; i < list.length; i += 1) {
      yield call(deleteConnectionsSaga, list[i]);
    }
    yield onFinish();
    yield call(loadServiceProvidersSaga);
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* deleteConnectionsSaga(item) {
  try {
    yield API.rest.participants.deleteAllPGFromSite(
      item.id,
      item.productGroups
    );
  } catch (err) {
    yield showErrorToast({ errors: err });
  }
}

export function* createAllSPConnectionsSaga({ payload }) {
  try {
    const { list, onFinish } = payload;
    const pid = getPID();
    yield put(setIsLoading(true));
    for (let i = 0; i < list.length; i += 1) {
      yield call(createConnectionsSaga, pid, list[i]);
    }
    yield onFinish();
    yield showSuccessToast('Сервис-провайдеры успешно добавлены');
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* createConnectionsSaga(pid, { siteId, body }) {
  try {
    yield API.rest.participants.createConnection(pid, siteId, body);
  } catch (err) {
    yield showErrorToast({ errors: err });
  }
}
