import { createAction } from '../../helpers/redux';
import { types } from '../types';

export const setIsLoading = createAction(types.SET_IS_LOADING);
export const saveLanguage = createAction(types.SET_LANGUAGE);
export const downloadFile = createAction(types.DOWNLOAD_FILE);
export const uploadFile = createAction(types.UPLOAD_FILE);
export const getSettings = createAction(types.GET_SETTINGS);
export const setSettings = createAction(types.SET_SETTINGS);
export const clearSettings = createAction(types.CLEAR_SETTINGS);
export const uploadFileWithIdentification = createAction(
  types.UPLOAD_FILE_WITH_IDENTIFICATION
);
