import fp from 'lodash/fp';
import { format } from 'date-fns';

import { types, Types } from '../types';

import { PAGINATION_DEFAULT } from './constants';

import { PageState } from './index';

type IReportsData = {
  createdTimestamp: string;
  creationDate: string;
  externalReportId: string;
  isAutoReport: false;
  markingCodesAmount: number;
  pg: string;
  reportId: string;
  status: string;
  type: string;
  updatedTimestamp: string;
};

type IPayload = {
  size: number;
  totalPages: number;
  totalElements: number;
  reportInfos: IReportsData[];
};

export type ReportsState = PageState<IReportsData>;

const initialState: ReportsState = {
  list: [],
  item: null,
  isLastPage: false,
  filter: {},
  historyFilters: [],
  savedFilters: [],
  pagination: PAGINATION_DEFAULT,
};

type ReportsActionType = Types[keyof Pick<
  Types,
  | 'SET_REPORTS'
  | 'REPORT_GET_LIST'
  | 'REPORT_SET_LIST'
  | 'CLEAR_REPORTS'
  | 'ADD_REPORTS_HISTORY_FILTERS'
  | 'ADD_REPORTS_SAVED_FILTERS'
>];

type ReportsAction = {
  type: ReportsActionType;
  payload: IPayload;
};

export const reducer = (state = initialState, action: ReportsAction) => {
  switch (action.type) {
    case types.REPORT_SET_LIST:
      return {
        ...state,
        isLastPage:
          action.payload.totalPages === state.pagination.page ||
          action.payload.totalPages === 0,
        list: [...state.list, ...parseReportList(action.payload)],
      };
    case types.SET_REPORTS:
      return {
        ...state,
        ...action.payload,
      };
    case types.CLEAR_REPORTS:
      return {
        ...state,
        list: [],
        isLastPage: false,
        item: {},
        filter: {},
        pagination: PAGINATION_DEFAULT,
        codesRetryList: [],
        isError: false,
      };
    default:
      return state;
    case types.ADD_REPORTS_HISTORY_FILTERS:
      return {
        ...state,
        historyFilters: action.payload,
      };
    case types.ADD_REPORTS_SAVED_FILTERS:
      return {
        ...state,
        savedFilters: action.payload,
      };
  }
};

const parseReportList = (data: IPayload) =>
  fp.pipe(
    fp.get('reportInfos'),
    fp.map((item: IReportsData) => ({
      ...item,
      creationDate: format(new Date(item.createdTimestamp), 'dd.MM.yyyy HH:mm'),
    }))
  )(data);
