import React from 'react';

import { Tab as MuiTab } from '@mui/material';
import styles from './index.module.scss';

export const Tab = ({
  disableFocusRipple = true,
  disableRipple = true,
  disableTouchRipple = true,
  ...rest
}) => (
  <MuiTab
    className={styles.container}
    disableFocusRipple={disableFocusRipple}
    disableRipple={disableRipple}
    disableTouchRipple={disableTouchRipple}
    {...rest}
  />
);
