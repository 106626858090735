import { createAction } from '../../helpers/redux';
import { types } from '../types';

export const getProfileData = createAction(types.PARTICIPANT_GET_PROFILE);
export const setProfileData = createAction(types.PARTICIPANT_SET_PROFILE);
export const updateParticipantDistribution = createAction(
  types.PARTICIPANT_UPDATE_DISTRIBUTION
);
export const setParticipantDistribution = createAction(
  types.PARTICIPANT_SET_DISTRIBUTION
);
export const clearProfile = createAction(types.PARTICIPANT_CLEAR_PROFILE);
