import { types, Types } from '../types';

import { PAGINATION_DEFAULT } from './constants';

import { PageState } from './index';

type DevicesData = {
  name: string;
  omsId: string;
  token: string;
};

export type DevicesState = PageState<DevicesData>;

const initialState: DevicesState = {
  list: [],
  isLastPage: false,
  item: null,
  filter: {},
  pagination: PAGINATION_DEFAULT,
};

type DevicesActionType = Types[keyof Pick<
  Types,
  | 'SET_DEVICES'
  | 'DEVICES_GET_LIST'
  | 'DEVICES_SET_LIST'
  | 'DEVICES_GET_ITEM'
  | 'DEVICES_SET_ITEM'
  | 'CREATE_DEVICES'
  | 'DELETE_DEVICE'
  | 'REMOVE_DEVICE_FROM_STORE'
  | 'CLEAR_DEVICES'
>];

type DevicesAction = {
  type: DevicesActionType;
  payload: any;
};

export const reducer = (state = initialState, action: DevicesAction) => {
  switch (action.type) {
    case types.DEVICES_SET_LIST: {
      const data = action.payload.data as DevicesData[];
      return {
        ...state,
        isLastPage: action.payload.totalPages === state.pagination.page,
        list: [...state.list, ...data],
      };
    }
    case types.REMOVE_DEVICE_FROM_STORE:
      return {
        ...state,
        list: [...state.list].filter((item) => item.token !== action.payload),
      };
    case types.DEVICES_SET_ITEM:
      return {
        ...state,
        item: action.payload,
      };
    case types.SET_DEVICES:
      return {
        ...state,
        ...action.payload,
      };
    case types.CLEAR_DEVICES:
      return initialState;
    default:
      return state;
  }
};
