import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Box, CircularProgress, MenuItem } from '@mui/material';
import { debounce } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { Autocomplete, AutocompleteProps } from '../../mui/autocomplete';

import css from './async-lookup-input.module.scss';

const SPINNER = <CircularProgress className={css.spinner} size={'1rem'} />;

/**
 * @deprecated
 * use `<AutocompleteAsync />` instead
 * */
export interface AsyncLookupInputProps<
  Value,
  Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false
> extends Omit<
    AutocompleteProps<Value, Multiple, DisableClearable, false>,
    | 'options'
    | 'filterOptions'
    | 'getOptionLabel'
    | 'renderOptionItem'
    | 'inputValue'
    | 'loading'
    | 'freeSolo'
    | 'label'
  > {
  searchOptions: (inputValue: string, signal: AbortSignal) => Promise<Option[]>;
  fetchValue: (
    value: Value,
    signal: AbortSignal
  ) => Promise<Option | undefined | null>;
  label?: string;
  searchTrigger?: (inputValue: string) => boolean;
  getOptionValue?: (option: Option) => Value;
  getOptionLabel?: (option: Option) => string;
  delay?: number;
  errorText?: React.ReactNode;
  translateOptionLabel?: boolean;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>
  ) => React.ReactNode;
}

interface LookupInputState<T> {
  optionValues: T[];
  loading: boolean;
  error?: boolean;
}

const defaultState: LookupInputState<unknown> = {
  loading: false,
  error: false,
  optionValues: [],
};

export interface AsyncLookupInputDefaultOption<Value> {
  label?: string;
  name?: string;
  value: Value;
}

const Component = <
  Value = string,
  Option extends Record<string, unknown> = AsyncLookupInputDefaultOption<Value>,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false
>(
  props: AsyncLookupInputProps<Value, Option, Multiple, DisableClearable>
) => {
  type Props = Required<
    AsyncLookupInputProps<Value, Option, Multiple, DisableClearable>
  >;
  const {
    delay = 350,
    fetchValue,
    searchOptions,
    searchTrigger = (inputValue) => inputValue.length >= 3,
    getOptionValue = ({ value }) => value,
    getOptionLabel = ({ label, name, value }) => label || name || value,
    onInputChange,
    onClose,
    errorText = <ErrorText />,
    noOptionsText,
    label,
    placeholder,
    InputProps,
    translateOptionLabel,
    renderOption,
    ...autocompleteProps
  } = props;
  const [lookupInputState, setLookupInputState] =
    useState<LookupInputState<Value>>(defaultState);
  const { loading, error, optionValues } = lookupInputState;
  const abortCtrlRef = useRef<AbortController>();
  const initValuesRef = useRef<boolean>(false);
  const { t } = useTranslation();
  const [optionRegistry, setOptionRegistry] = useState<Map<Value, Option>>(
    new Map()
  );
  const [inputValue, setInputValue] = useState<string>('');

  const addOptionsToRegistry = useCallback(
    (options: Option[]) => {
      if (options.length > 0) {
        setOptionRegistry((optionRegistry) => {
          return options.reduce<Map<Value, Option>>(
            (map, option) => map.set(getOptionValue(option), option),
            new Map(optionRegistry)
          );
        });
      }
    },
    [getOptionValue]
  );

  const createSetStateCtrl = useCallback(
    (abortCtrl: AbortController) => {
      function setState(state: LookupInputState<Value>) {
        if (abortCtrlRef.current === abortCtrl) {
          setLookupInputState(state);
        }
      }
      return {
        setLoading: () => {
          setState({ optionValues: [], loading: true });
        },
        setOptions: (options: Option[]) => {
          addOptionsToRegistry(options);
          const optionValues = options.map(getOptionValue);
          setState({ optionValues, loading: false });
        },
        setError: (error: unknown) => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(error);
          }
          setState({ optionValues: [], loading: false, error: true });
        },
      };
    },
    [addOptionsToRegistry, getOptionValue]
  );

  useEffect(() => {
    if (!initValuesRef.current) {
      initValuesRef.current = true;
      const abortCtrl = new AbortController();
      abortCtrlRef.current = abortCtrl;
      const requests = (valueToArray(props.value) as Value[])
        .filter((value) => !optionRegistry.has(value))
        .map((value) => fetchValue(value, abortCtrl.signal));
      if (requests.length > 0) {
        const { setLoading, setOptions, setError } =
          createSetStateCtrl(abortCtrl);
        setLoading();
        Promise.all(requests)
          .then((options) => {
            const optionList = options.filter(hasValue);
            setOptions(optionList);
            return options;
          })
          .catch(setError);
      }
      return () => abortCtrl.abort();
    }
  }, [createSetStateCtrl, fetchValue, optionRegistry, props.value]);

  const debouncedFetchOptions = useMemo(() => {
    async function fetch(inputValue: string, abortCtrl: AbortController) {
      const { setLoading, setOptions, setError } =
        createSetStateCtrl(abortCtrl);
      setLoading();
      const normalizedValue = (inputValue || '').trim();
      const isTriggered = searchTrigger(normalizedValue);
      try {
        const options = isTriggered
          ? await searchOptions(normalizedValue, abortCtrl.signal)
          : [];
        setOptions(options);
      } catch (error) {
        setError(error);
      }
    }
    return debounce(delay, fetch);
  }, [createSetStateCtrl, delay, searchOptions, searchTrigger]);

  const componentsProps = useMemo(() => {
    const isTriggered = searchTrigger(inputValue.trim());
    return !isTriggered || loading
      ? { paper: { sx: { display: 'none' } } }
      : props.componentsProps;
  }, [inputValue, loading, props.componentsProps, searchTrigger]);

  const InputPropsWithSpinner = useMemo<Props['InputProps']>(
    () => ({
      ...InputProps,
      ...(loading && { endAdornment: SPINNER }),
    }),
    [InputProps, loading]
  );

  return (
    <Autocomplete<Value, Multiple, DisableClearable, false>
      {...autocompleteProps}
      InputProps={InputPropsWithSpinner}
      componentsProps={componentsProps}
      data-testid="AsyncLookupInput"
      filterOptions={(optionValues) => optionValues}
      getOptionLabel={(optionValue) => {
        if (!loading) {
          const option = optionRegistry.get(optionValue);
          return option ? getOptionLabel(option) : optionValue;
        }
        return '';
      }}
      inputValue={inputValue}
      loading={loading}
      noOptionsText={error ? errorText : noOptionsText}
      options={optionValues}
      placeholder={placeholder ?? label}
      renderOption={
        renderOption
          ? renderOption
          : (renderOptionProps, label) => {
              return (
                <MenuItem {...renderOptionProps}>
                  {translateOptionLabel ? t(label) : label}
                </MenuItem>
              );
            }
      }
      onClose={(event, reason) => {
        abortCtrlRef.current?.abort();
        onClose?.(event, reason);
      }}
      onInputChange={(event, value, reason) => {
        setInputValue(value);
        if (['input', 'clear'].includes(reason)) {
          abortCtrlRef.current?.abort();
          const abortCtrl = new AbortController();
          abortCtrlRef.current = abortCtrl;
          debouncedFetchOptions(value, abortCtrl);
        }
        onInputChange?.(event, value, reason);
      }}
    />
  );
};

function hasValue<T>(value?: T | null | undefined | ''): value is T {
  return value !== null && value !== undefined && value !== '';
}

function valueToArray<T>(value?: T | T[] | null): T[] {
  if (Array.isArray(value)) {
    return value;
  }
  return hasValue(value) ? [value as T] : [];
}

function ErrorText() {
  return <Box>Возникла ошибка при загрузке данных</Box>;
}

/**
 * @deprecated
 * use `<AutocompleteAsync />` instead
 * */
export const AsyncLookupInput = memo(Component) as typeof Component;
