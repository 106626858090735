import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { SettingsState, reducer as settingsReducer } from './settings';
import { OrdersState, reducer as ordersReducer } from './orders';
import { reducer as rejectionsReducer, RejectionState } from './rejections';
import { MarkingState, reducer as markingReducer } from './marking';
import { AggregationState, reducer as aggregationReducer } from './aggregation';
import { CurrentUserState, reducer as currentUserReducer } from './currentUser';
import { DevicesState, reducer as devicesReducer } from './devices';
import { ReportsState, reducer as reportsReducer } from './reports';
import { AuthState, reducer as authReducer } from './auth';
import {
  IntroductionState,
  reducer as introductionReducer,
} from './introduction';
import {
  ParticipantState,
  reducer as participantsReducer,
} from './participants';
import {
  reducer as serviceProvidesReducer,
  ServiceProvideState,
} from './service-provides';

export type CountryCode = 'HY' | 'AM' | 'AZ' | 'KZ' | 'UZ';
export type Language = 'en' | 'ru' | 'uz' | 'hy' | 'kz';
export type Name = Record<Language, string>;

export type PaginationData = { size: number; page: number };

export type Filters = {
  id: string;
  value: string;
  filterLabel: string;
  filterValue: string;
};

export type AllFilters = {
  id: string;
  name: string;
  filters: Filters[];
};

export type PageState<T extends object = {}, I = unknown> = {
  list: T[];
  item?: I;
  filter: unknown;
  historyFilters: AllFilters[];
  savedFilters: AllFilters[];
  sorting?: unknown;
  isLastPage: boolean;
  pagination: PaginationData;
};

export type RootState = {
  auth: AuthState;
  aggregation: AggregationState;
  devices: DevicesState;
  marking: MarkingState;
  settings: SettingsState;
  currentUser: CurrentUserState;
  participants: ParticipantState;
  orders: OrdersState;
  reports: ReportsState;
  rejections: RejectionState;
  serviceProvider: ServiceProvideState;
  introduction: IntroductionState;
};

export const createRootReducer = (history: unknown) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    devices: devicesReducer,
    settings: settingsReducer,
    aggregation: aggregationReducer,
    orders: ordersReducer,
    rejections: rejectionsReducer,
    marking: markingReducer,
    currentUser: currentUserReducer,
    reports: reportsReducer,
    participants: participantsReducer,
    serviceProvider: serviceProvidesReducer,
    introduction: introductionReducer,
  });
