import React, { FC, useMemo } from 'react';

import { ChipStatus } from '@ibox/ui';

import { REPORT_STATUS_NAMES } from '../../pages/reports/constants';

const PRODUCT_STATUSES = {
  ACTIVE: 'success',
  PENDING: 'secondary',
  REJECTED: 'error',
  CLOSED: 'primary',
  EXPIRED: 'error',
  EXHAUSTED: 'info',
};

const ORDER_STATUSES = {
  CREATED: 'default',
  PENDING: 'secondary',
  READY: 'success',
  REJECTED: 'error',
  CLOSED: 'primary',
  EXPIRED: 'error',
};

const REPORT_STATUSES = {
  DRAFT: 'default',
  READY_TO_SEND: 'success',
  PENDING: 'secondary',
  SENT: 'success',
  REJECTED: 'error',
};

type ColorType =
  | 'default'
  | 'secondary'
  | 'success'
  | 'primary'
  | 'error'
  | 'info'
  | 'warning';

type StatusProps = {
  label: string;
  status:
    | keyof typeof ORDER_STATUSES
    | keyof typeof REPORT_STATUSES
    | keyof typeof PRODUCT_STATUSES;
  className?: string;
  isReportStatus?: boolean;
  isProductStatus?: boolean;
};

export const Status: FC<StatusProps> = ({
  label,
  status,
  isReportStatus,
  isProductStatus,
  ...props
}) => {
  const color = useMemo(() => {
    if (!status) {
      return 'default';
    }
    if (isReportStatus) {
      return REPORT_STATUSES[
        status as keyof typeof REPORT_STATUS_NAMES
      ] as ColorType;
    }
    if (isProductStatus) {
      return PRODUCT_STATUSES[
        status as keyof typeof PRODUCT_STATUSES
      ] as ColorType;
    }
    return ORDER_STATUSES[status as keyof typeof ORDER_STATUSES] as ColorType;
  }, [isProductStatus, isReportStatus, status]);

  return (
    <ChipStatus
      {...props}
      small
      color={color}
      data-testid="status"
      label={label}
    />
  );
};
