import fp from 'lodash/fp';
import Cookies from 'js-cookie';

import {
  getProductGroupList,
  PRODUCT_GROUPS_LIST,
  PRODUCT_GROUPS_NAMES,
} from '../constants';
import { COUNTRIES } from '../constants/countries';
import { Translate } from '../components/translate';

export const getPgListByCountry = (country, t) => {
  const token = getToken()?.split('.')[1];
  if (!token) {
    return [];
  }
  const payloadObj = JSON.parse(b64DecodeUnicode(token));
  const availablePg = payloadObj.product_group_info
    .filter((el) => el.status === '5' || el.status === '4')
    .map((item) => item.name);
  const list = t ? getProductGroupList(t) : PRODUCT_GROUPS_LIST;
  return list
    .filter((item) => item.countries.includes(country))
    .filter((item) => availablePg.includes(item.value));
};

export const parsePgList = (list, reportType) => {
  const token = getToken()?.split('.')[1];
  if (!token) {
    return [];
  }
  const payloadObj = JSON.parse(b64DecodeUnicode(token));
  return payloadObj.product_group_info
    .filter((el) => el.status === '5' || el.status === '4')
    .filter((pg) => {
      const found = list.find((el) => el.pg === pg.name);
      if (!found) {
        return false;
      }
      if (reportType === 'aggregation') {
        return found.reportsPermit.aggregation;
      }
      if (reportType === 'dropout') {
        return found.reportsPermit.dropout;
      }
      if (reportType === 'introduction') {
        return found.reportsPermit.introduction;
      }
      return found.paymentFlow === 'DEFAULT';
    })
    .map((pg) => ({
      value: pg.name,
      label: Translate(PRODUCT_GROUPS_NAMES[pg.name]),
    }));
};

export const getParamFromToken = (param, json, tokenType = 'access_token') => {
  const data = getTokenData(json, tokenType);
  return data[param];
};

export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function deleteCookie(name, domain) {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${domain}`;
}

export function setCookie(cookieName, value) {
  deleteCookie(cookieName, getCurrentDomain());
  Cookies.set(cookieName, value, {
    domain: getCurrentDomain(),
  });
}

export function getToken() {
  const count = getCookie('count') || 0;
  const token = new Array(+count)
    .fill(1)
    .map((_v, i) => getCookie(`tokenPart${i + 1}`))
    .join('');
  return count ? token : null;
}

export const getTokenData = (json, tokenType) => {
  const fullToken = json[tokenType];
  const token = fullToken.split('.')[1];
  return JSON.parse(Buffer.from(token, 'base64').toString());
};

export const getShortName = (fullName) =>
  fullName
    .split(' ')
    .map((item, idx) => (idx === 0 ? item : `${item[0]}.`))
    .join(' ');

export const getCountryName = (country) =>
  fp.pipe(
    fp.find((item) => item.value === country),
    fp.get('label')
  )(COUNTRIES);

const b64DecodeUnicode = (str) => {
  const base64 = str.replace(/-/g, '+').replace(/_/g, '/');
  return decodeURIComponent(
    Array.prototype.map
      .call(
        atob(base64),
        (c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      )
      .join('')
  );
};
function getCurrentDomain() {
  return `${window.location.hostname.split('.').slice(-2).join('.')}`;
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${getCurrentDomain()}`;
  }
  localStorage.clear();
}

export function isAdmin() {
  const token = getToken().split('.')[1];
  const payloadObj = JSON.parse(b64DecodeUnicode(token));
  const { authorities } = payloadObj;
  return authorities.includes('ROLE_ADMIN');
}
