import { put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { setIsLoading } from '../actions/common';
import { setSendedDoc, setItem } from '../actions/puttingIntoTurnover';
import { API } from '../../api';

import { routes } from '../../routes';
import { showErrorToast } from '../../components/toastWrapper';

export function* sendDocSaga(action) {
  try {
    const { pg, createDraft, currentDraft, ...otherOptions } = action.payload;
    const body = { ...otherOptions };
    const query = {
      pg,
      ...(createDraft ? { draft: true } : {}),
    };
    yield put(setIsLoading(true));
    if (currentDraft) {
      yield API.rest.reports.introductionUpdate(body, query, currentDraft);
    } else {
      yield API.rest.reports.introduction(body, query);
    }
    yield put(setSendedDoc({ value: true }));
    yield put(setIsLoading(false));
    yield put(push(routes.documents()));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* loadPuttingIntoTurnoverItemSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.reports.item(action.payload);
    yield put(setItem({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}
