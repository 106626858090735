import React from 'react';

import cn from 'classnames';
import { Stack, StackProps } from '@mui/material';

import styles from './common-styles.module.scss';

export const MainMenuContainer = React.memo<StackProps>((props) => (
  <Stack {...props} className={cn(styles.MainMenuContainer, props.className)} />
));
