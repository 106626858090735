import { format } from 'date-fns';

import { Types, types } from '../types';
import {
  ProductGroupTypes,
  REPORT_STATUS_NAMES,
} from '../../pages/reports/constants';
import { PRODUCT_GROUPS_NAMES } from '../../constants';
import {
  identificationTypesNames,
  releaseMethodTypeNames,
} from '../../pages/Introduction/constantes';
import { getSntinsLength } from '../../pages/Introduction/utils';
import i18n from '../../i18n';

type ReportStatusTypes =
  | 'DRAFT'
  | 'READY_TO_SEND'
  | 'PENDING'
  | 'SENT'
  | 'REJECTED';

type IntroductionDataType = {
  codes: string[];
  createdTimestamp: string;
  declarationNumber: number | undefined;
  expirationDate: Date | null;
  expirationDateFormatted: null;
  identificationCodes: number;
  identificationCodesTG: string | undefined;
  identificationCodesWith: string | null;
  identificationType: string;
  identificationTypeName: string;
  pg: ProductGroupTypes;
  pgName: string;
  primaryDocNumber: number | undefined;
  productionDate: Date | null;
  productionDateFormatted: Date | null;
  rejectReason: string;
  releaseMethodType: 'IMPORT' | 'PRODUCTION' | 'EAEU';
  releaseMethodTypeName: string;
  senderInn: string;
  seriesNumber: number | undefined;
  status: ReportStatusTypes;
  statusName: string;
  transportCodes: string[];
  form?: Record<string, (...args: unknown[]) => unknown>;
};

type ResponseDataType = {
  createdTimestamp: string;
  json: {
    identificationType: 'sntin' | 'sntinAndSerial' | 'sntinAndImei';
    isSpdn: false;
    participantId: string;
    releaseMethodType: 'IMPORT' | 'PRODUCTION' | 'EAEU';
    sntins: { sntins: string[] };
    productionDate: string;
    expirationDate: string;
    primaryDocNumber: string;
    declarationNumber: string;
    transportCodes: string[];
    seriesNumber: string;
  };
  pg: ProductGroupTypes;
  reportId: string;
  status: ReportStatusTypes;
  type: string;
  updatedTimestamp: string;
  value?: string;
  rejectReason?: string;
};

export type IntroductionState = {
  item: IntroductionDataType | null;
  sended: boolean;
};

const initialState: IntroductionState = {
  sended: false,
  item: null,
};

type IntroductionActionType = Types[keyof Pick<
  Types,
  | 'SENDED_PUTTING_INTO_TURNOVER'
  | 'SET_ITEM_PUTTING_INTO_TURNOVER'
  | 'CLEAR_PUTTING_INTO_TURNOVER'
>];

type IntroductionAction = {
  type: IntroductionActionType;
  payload: ResponseDataType;
};

export const reducer = (state = initialState, action: IntroductionAction) => {
  switch (action.type) {
    case types.SENDED_PUTTING_INTO_TURNOVER:
      return {
        ...state,
        sended: action.payload.value,
      };
    case types.SET_ITEM_PUTTING_INTO_TURNOVER:
      return {
        ...state,
        item: parseItem(action.payload),
      };
    case types.CLEAR_PUTTING_INTO_TURNOVER:
      return initialState;
    default:
      return state;
  }
};

const parseItem = (data: ResponseDataType) => {
  const { sntinsLength, additional } = getSntinsLength(
    { sntins: data?.json?.sntins?.sntins },
    data?.json?.identificationType
  );
  return {
    ...data,
    form: {
      pgName:
        PRODUCT_GROUPS_NAMES[data.pg as keyof typeof PRODUCT_GROUPS_NAMES],
      createdTimestamp: data?.createdTimestamp
        ? format(new Date(data.createdTimestamp), 'dd.MM.yyyy HH:mm')
        : null,
      productionDateFormatted: data?.json?.productionDate
        ? format(new Date(data.json.productionDate), 'dd.MM.yyyy')
        : null,
      expirationDateFormatted: data?.json?.expirationDate
        ? format(new Date(data.json.expirationDate), 'dd.MM.yyyy')
        : null,
      senderInn: data?.json?.participantId,
      rejectReason: data?.rejectReason || '—',
      status: data.status,
      statusName:
        REPORT_STATUS_NAMES[data.status as keyof typeof REPORT_STATUS_NAMES],
      releaseMethodTypeName: data?.json?.releaseMethodType
        ? i18n.t(
            releaseMethodTypeNames[
              data?.json
                ?.releaseMethodType as keyof typeof releaseMethodTypeNames
            ]
          )
        : '—',

      identificationTypeName: i18n.t(
        identificationTypesNames[data?.json?.identificationType]
      ),
      pg: data.pg,
      identificationType: data?.json.identificationType,
      releaseMethodType: data?.json.releaseMethodType,
      productionDate: data?.json?.productionDate
        ? new Date(data.json.productionDate)
        : null,
      expirationDate: data?.json?.expirationDate
        ? new Date(data.json.expirationDate)
        : null,
      primaryDocNumber: data?.json?.primaryDocNumber,
      declarationNumber: data?.json?.declarationNumber,
      seriesNumber: data?.json?.seriesNumber,
      identificationCodesTG: data?.json?.transportCodes?.length,
      transportCodes: data?.json?.transportCodes || [],
      identificationCodes: sntinsLength,
      identificationCodesWith: additional,
      codes: data?.json?.sntins?.sntins,
    },
  };
};
