import { put } from 'redux-saga/effects';

import { setIsLoading } from '../actions/common';
import { API } from '../../api';
import { showErrorToast } from '../../components/toastWrapper';
import { setItem, setError } from '../actions/marking';
import { history } from '../../store';
import { routes } from '../../routes';

export function* loadMarkingItemSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.reports.item(action.payload);
    yield put(setItem({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(setError(true));
    yield showErrorToast({ errors: err });
  }
}

export function* createMarkingSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.reports.utilisation(payload.data, payload.query);
    yield put(setIsLoading(false));
    history.push(routes.documents());
  } catch (err) {
    console.error('err', err);
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* updateMarkingSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.reports.utilisationUpdate(
      payload.data,
      payload.query,
      payload.reportId
    );
    yield put(setIsLoading(false));
    history.push(routes.documents());
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}
