import { connect } from 'react-redux';

import { RootState } from '../store/reducers';

import RestAPI from './rest';

type ApiType = {
  rest?: any;
};

export const API: ApiType = {};

export const APIConnector = connect(
  (state: RootState) => ({ auth: state.auth }),
  {}
)(({ children, ...props }) => {
  API.rest = RestAPI(props);
  return children;
});
