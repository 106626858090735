import React from 'react';

import { ButtonBase } from '@mui/material';

import styles from './header-logo.module.scss';

export type HeaderLogoProps = {
  expanded?: boolean;
  hovered?: boolean;
  logo?: string;
  logoSmall: string;
  onClick?: VoidFunction;
};

export const HeaderLogo = React.memo<HeaderLogoProps>(
  ({ expanded, hovered, logo, logoSmall, onClick }) => (
    <ButtonBase className={styles.buttonBase} onClick={onClick}>
      {(expanded || hovered) && logo ? (
        <div className={styles.menuLogo}>
          <img alt="logo" src={logo} />
        </div>
      ) : (
        <div className={styles.menuLogo}>
          <div className={styles.logoContainer}>
            <img alt="logoSmall" src={logoSmall} />
          </div>
        </div>
      )}
    </ButtonBase>
  )
);
