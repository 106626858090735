import { CSSObject, styled, Theme } from '@mui/material/styles';
import { Drawer } from '@mui/material';

const commonMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'var(--mui-palette-grey-50)',
  border: 'none',
  boxSizing: 'border-box',
  borderRight: '1px solid var(--mui-palette-grey-300)',
});

const openedMixin = (theme: Theme): CSSObject => ({
  width: `320px`,
  ...commonMixin(theme),
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: '88px',
  ...commonMixin(theme),
});

export const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: `320px`,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  borderRight: '1px solid var(--mui-palette-grey-400)',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
