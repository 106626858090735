import { put } from 'redux-saga/effects';

import { setIsLoading } from '../actions/common';
import { API } from '../../api';
import { setItem } from '../actions/aggregation';
import { history } from '../../store';
import { routes } from '../../routes';
import { showErrorToast } from '../../components/toastWrapper';

export function* loadAggregationsItemSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.reports.item(payload);
    yield put(setItem({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* createAggregationSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.reports.aggregation(payload.data, payload.query);
    yield put(setIsLoading(false));
    history.push(routes.documents());
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* updateAggregationSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.reports.aggregationUpdate(
      payload.data,
      payload.query,
      payload.reportId
    );
    yield put(setIsLoading(false));
    history.push(routes.documents());
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}
