import axios from 'axios';

import { setAuthData } from '../store/actions/auth';
import { storeTokens } from '../store/sagas/auth';
import { getParamFromToken } from '../utils/utils';

import { API } from './index';

let isRefreshing = false;
let subscribers = [];

function onRefreshed({ access_token }) {
  subscribers.map((cb) => cb(access_token));
}

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

export const incerceptor = (store) => {
  axios.interceptors.response.use(null, async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401) {
      if (
        originalRequest.url === '/oauth/oauth2' &&
        originalRequest.method !== 'delete'
      ) {
        return Promise.reject(error.response);
      }
      if (!isRefreshing) {
        isRefreshing = true;
        // eslint-disable-next-line promise/catch-or-return
        API.rest.auth.reconnect().then((token) => {
          const exp_date = getParamFromToken('exp', token, 'access_token');
          const exp_date_refresh = getParamFromToken(
            'exp',
            token,
            'refresh_token'
          );
          storeTokens({ ...token, exp_date, exp_date_refresh });
          // eslint-disable-next-line max-len
          store.dispatch(
            setAuthData(
              token
                ? { ...token, exp_date, exp_date_refresh }
                : { isNotAuth: true }
            )
          );
          isRefreshing = false;
          // eslint-disable-next-line promise/always-return
          if (token) {
            onRefreshed(token);
          }
          subscribers = [];
        });
      }
      return new Promise((resolve) => {
        subscribeTokenRefresh((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
    }
    return Promise.reject(error);
  });
};
