import { Types, types } from '../types';
import { getCookie } from '../../utils/utils';

import { CountryCode } from './index';

export type SettingsState = {
  common: {
    countryCode: CountryCode;
  };
  isLoading: boolean;
  language: CountryCode;
  settings: any;
};

const initialState: SettingsState = {
  common: {
    countryCode: window?.env?.COUNTRY_CODE || 'KZ',
  },
  isLoading: false,
  language: (getCookie('language') as CountryCode) || 'ru',
  settings: null,
};

type SettingsActionType = Types[keyof Pick<
  Types,
  | 'SET_COMMON_SETTINGS'
  | 'SET_IS_LOADING'
  | 'SET_LANGUAGE'
  | 'SET_SETTINGS'
  | 'CLEAR_SETTINGS'
>];

type SettingsAction = {
  type: SettingsActionType;
  payload: any;
};

export const reducer = (state = initialState, action: SettingsAction) => {
  switch (action.type) {
    case types.SET_COMMON_SETTINGS:
      return {
        ...state,
        common: action.payload,
      };
    case types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case types.SET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case types.CLEAR_SETTINGS:
      return {
        ...state,
        settings: null,
      };
    default:
      return state;
  }
};
