import React, { lazy, Suspense } from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';

import './index.scss';
import { Spinner } from '@ibox/ui';

import { routes } from '../../routes';

import { HeaderView } from './header';

const OrdersContainer = lazy(() =>
  import('../../pages/orders/orders-container').then((module) => ({
    default: module.OrdersContainer,
  }))
);
const ReportsContainer = lazy(() =>
  import('../../pages/reports/reports-container').then((module) => ({
    default: module.ReportsContainer,
  }))
);
const DeviceContainer = lazy(() =>
  import('../../pages/devices/device-container').then((module) => ({
    default: module.DeviceContainer,
  }))
);
const SPContainer = lazy(() =>
  import('../../pages/serviceProviders/service-provider-container').then(
    (module) => ({
      default: module.SPContainer,
    })
  )
);
const ProfilePage = lazy(() =>
  import('../../pages/profile').then((module) => ({
    default: module.Profile,
  }))
);
const AboutPage = lazy(() =>
  import('../../pages/profile/about').then((module) => ({
    default: module.AboutPage,
  }))
);

export const Layout = () => (
  <div className="layout">
    <div className="header">
      <HeaderView />
    </div>
    <div className="main">
      <div className="page">
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route component={OrdersContainer} path={routes.orders()} />
            <Route component={SPContainer} path={routes.serviceProviders()} />
            <Route exact path="/">
              <Redirect to={routes.orders()} />
            </Route>
            <Route component={ReportsContainer} path={routes.aggregations()} />
            <Route component={ReportsContainer} path={routes.marking()} />
            <Route component={ReportsContainer} path={routes.rejections()} />
            <Route component={ReportsContainer} path={routes.introduction()} />
            <Route component={ReportsContainer} path={routes.documents()} />
            <Route component={DeviceContainer} path={routes.devices()} />
            <Route exact component={ProfilePage} path={routes.profile()} />
            <Route exact component={AboutPage} path={routes.about()} />
            <Route
              path="*"
              render={({ location }) => (
                <h3>
                  404:
                  {location.pathname}
                </h3>
              )}
            />
          </Switch>
        </Suspense>
      </div>
    </div>
  </div>
);
