import React from 'react';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { ToastWrapper } from '@ibox/ui';

import { store, history } from './store';
import { APIConnector } from './api';
import { Layout } from './components/layout';
import { Theme } from './components/themes';
import { Auth } from './pages/auth';
import { incerceptor } from './api/inceptor';

incerceptor(store);

export const App = () => {
  return (
    <Provider store={store}>
      <APIConnector>
        <Theme>
          <Auth>
            <ConnectedRouter history={history}>
              <Layout />
            </ConnectedRouter>
          </Auth>
          <ToastWrapper />
        </Theme>
      </APIConnector>
    </Provider>
  );
};
