export const getLocalstorageService = <T>(key: string) => {
  const get = (): T | undefined => {
    try {
      return JSON.parse(localStorage.getItem(key) ?? '');
    } catch (e) {
      return undefined;
    }
  };

  const set = (value: T): void => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  return { get, set };
};
