import { Types, types } from '../types';
import { getTokenData } from '../../utils/utils';

type CurrentUserActionType = Types[keyof Pick<
  Types,
  'CLEAR_CURRENT_USER' | 'SET_CURRENT_USER'
>];

type ProductGroupEnum =
  | 'RETAIL'
  | 'PRODUCER'
  | 'IMPORTER'
  | 'TRADE_PARTICIPANT'
  | 'WHOLESALER';

type ProductGroup = {
  name: string;
  status: string;
  types: ProductGroupEnum[];
};

export type CurrentUserState = {
  user_status: string;
  id?: number;
  inn: string;
  user_name: string | null;
  full_name: string | null;
  authorities: string[];
  product_group_info: ProductGroup[];
};

const initialState: CurrentUserState = {
  user_status: '',
  inn: '',
  user_name: '',
  full_name: '',
  authorities: [],
  product_group_info: [],
};

type CurrentUserAction = {
  type: CurrentUserActionType;
  payload: unknown;
};

export const reducer = (state = initialState, action: CurrentUserAction) => {
  switch (action.type) {
    case types.CLEAR_CURRENT_USER:
      return {};
    case types.SET_CURRENT_USER:
      return {
        ...getTokenData(action.payload, 'access_token'),
      };
    default:
      return state;
  }
};
