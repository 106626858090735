import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationKZ from './locales/kz/translations.json';
import translationKG from './locales/kg/translations.json';
import translationRU from './locales/ru/translations.json';
import translationUZ from './locales/uz/translations.json';
import translationEN from './locales/en/translations.json';
import translationAM from './locales/am/translations.json';

const resources = {
  kz: {
    translation: translationKZ,
  },
  kg: {
    translation: translationKG,
  },
  hy: {
    translation: translationAM,
  },
  uz: {
    translation: translationUZ,
  },
  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'ru',
  nsSeparator: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

// eslint-disable-next-line import/no-default-export
export default i18n;
