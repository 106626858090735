import { Translate } from '../../components/translate';

export const identificationTypes = {
  sntin: 'sntin',
  sntinAndSerial: 'sntinAndSerial',
  sntinAndImei: 'sntinAndImei',
};
export const identificationTypesNames = {
  [identificationTypes.sntin]: 'Только коды идентификации',
  [identificationTypes.sntinAndSerial]: 'Коды идентификации с серийным номером',
  [identificationTypes.sntinAndImei]: 'Коды идентификации с IMEI',
};

export const identificationTypesList = [
  {
    value: identificationTypes.sntin,
    label: Translate(identificationTypesNames[identificationTypes.sntin]),
    productGroups: [
      'lp',
      'shoes',
      'tobacco',
      'pharma',
      'milk',
      'alcohol',
      'water',
      'beer',
      'appliances',
      'telephones',
      'perfumery',
      'tires',
      'conserve',
      'softdrinks',
      'chemistry',
    ],
  },
  {
    value: identificationTypes.sntinAndSerial,
    label: Translate(
      identificationTypesNames[identificationTypes.sntinAndSerial]
    ),
    productGroups: ['laptops'],
  },
  {
    value: identificationTypes.sntinAndImei,
    label: Translate(
      identificationTypesNames[identificationTypes.sntinAndImei]
    ),
    productGroups: ['telephones'],
  },
];

export const releaseMethodTypes = {
  PRODUCTION: 'PRODUCTION',
  IMPORT: 'IMPORT',
  EAEU: 'EAEU',
};
export const releaseMethodTypeNames = {
  PRODUCTION: 'Производство внутри страны',
  IMPORT: 'Ввезен в страну (Импорт)',
  EAEU: 'Ввезен в страну (ЕАЭС)',
};

export const releaseOfGoodsOptions = [
  {
    value: releaseMethodTypes.PRODUCTION,
    label: Translate(releaseMethodTypeNames[releaseMethodTypes.PRODUCTION]),
  },
  {
    value: releaseMethodTypes.IMPORT,
    label: Translate(releaseMethodTypeNames[releaseMethodTypes.IMPORT]),
  },
  {
    value: releaseMethodTypes.EAEU,
    label: Translate(releaseMethodTypeNames[releaseMethodTypes.EAEU]),
  },
];
