import React, { FC } from 'react';

import cn from 'classnames';
import {
  IconButton,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material';

import { composeRef, useCheckHasAutofill, useEvent } from '../../utils';
import { Icons } from '../../application/icons';

import styles from './textfield.module.scss';

export type TextFieldProps = MuiTextFieldProps & {
  disableClearable?: boolean;
  trimSpaces?: boolean;
};

type Props = Required<TextFieldProps>;

export const TextField: FC<TextFieldProps> = ({
  InputProps,
  InputLabelProps,
  disableClearable = true,
  trimSpaces,
  onChange,
  ...props
}) => {
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [hasAutofill, checkHasAutofill] = useCheckHasAutofill();

  const inputInnerRef = React.useRef<HTMLTextAreaElement | HTMLInputElement>();
  const inputRef = composeRef(inputInnerRef, props.inputRef);

  const checkIsEmpty = () => setIsEmpty(!inputInnerRef.current?.value);

  React.useEffect(() => checkIsEmpty(), [props.value]);

  const inputLabelProps = React.useMemo(
    () =>
      hasAutofill ? { ...InputLabelProps, shrink: true } : InputLabelProps,
    [InputLabelProps, hasAutofill]
  );

  const handleChange = useEvent<Props['onChange']>((event) => {
    event.target.value = trimSpaces
      ? event.target.value.trim()
      : event.target.value;
    onChange?.(event);
    checkIsEmpty();
  });

  const handleClear = useEvent(() => {
    if (inputInnerRef.current) {
      inputInnerRef.current.value = '';
      handleChange({
        target: inputInnerRef.current,
        currentTarget: inputInnerRef.current,
        type: 'clear',
      } as React.ChangeEvent<HTMLInputElement>);
    }
  });

  const handleAnimationStart = useEvent<
    Required<Props['InputProps']>['onAnimationStart']
  >((e) => {
    checkHasAutofill(e);
    InputProps?.onAnimationStart?.(e);
  });

  const inputProps = React.useMemo(
    () => ({
      ...InputProps,
      ...(!disableClearable && {
        endAdornment: (
          <IconButton
            className={styles['clear-button']}
            size="small"
            onClick={handleClear}
          >
            <Icons.Clear />
          </IconButton>
        ),
      }),
      onAnimationStart: handleAnimationStart,
    }),
    [InputProps, disableClearable, handleClear, handleAnimationStart]
  );

  return (
    <MuiTextField
      {...props}
      InputLabelProps={inputLabelProps}
      InputProps={inputProps}
      className={cn(props.className, styles.textField, {
        [styles.clearable]: !disableClearable,
        [styles.empty]: isEmpty,
        [styles.error]:
          (props.required && isEmpty && !hasAutofill) || props.error,
      })}
      inputRef={inputRef}
      onChange={handleChange}
    />
  );
};
