import React, { FC, forwardRef, useMemo, memo } from 'react';

import cn from 'classnames';
import { ru, enUS, uz, hy } from 'date-fns/locale';
import {
  default as ReactDatePicker,
  type ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  TextField,
  InputAdornment,
  Box,
  type TextFieldProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Icon } from '../icons';
import { PartialKeys } from '../../utils/types.utils';
import { constVoid } from '../../utils/helpers';

import css from './datepicker.module.scss';

registerLocale('hy', hy);
registerLocale('ru', ru);
registerLocale('en', enUS);
registerLocale('kz', enUS);
registerLocale('uz', uz);

export type Language = 'en' | 'ru' | 'uz' | 'hy' | 'kz';

export type DatePickerProps = PartialKeys<ReactDatePickerProps, 'onChange'> & {
  helperText?: string;
  label?: string;
  error?: boolean;
  fullWidth?: boolean;
};

type DateInputProps = TextFieldProps & { readOnly?: boolean };

const DateInput = forwardRef<HTMLDivElement, DateInputProps>((props, ref) => {
  return (
    <TextField
      {...props}
      InputProps={{
        autoComplete: 'off',
        readOnly: props.readOnly,
        inputProps: {
          onChange: props.onChange,
        },
        endAdornment: (
          <InputAdornment position="end">
            <Icon name="Calendar" />
          </InputAdornment>
        ),
      }}
      className={cn(css['custom-input'])}
      ref={ref}
    />
  );
});

export const DatePicker: FC<DatePickerProps> = memo((props) => {
  const {
    helperText,
    placeholderText,
    label,
    title,
    error,
    ariaInvalid,
    dateFormat = 'dd.MM.yyyy',
    showMonthDropdown = true,
    showYearDropdown = true,
    scrollableYearDropdown = true,
    showPopperArrow = false,
    fullWidth = false,
    className = '',
    required = false,
    disabled = false,
    onChange = constVoid,
    ...reactDatePickerProps
  } = props;

  const { t, i18n } = useTranslation();

  const months = useMemo(() => {
    return [
      t('Январь'),
      t('Февраль'),
      t('Март'),
      t('Апрель'),
      t('Май'),
      t('Июнь'),
      t('Июль'),
      t('Август'),
      t('Сентябрь'),
      t('Октябрь'),
      t('Ноябрь'),
      t('Декабрь'),
    ];
  }, [i18n.language]);

  const days: Record<number, string> = useMemo(
    () => ({
      0: t('Вс'),
      1: t('Пн'),
      2: t('Вт'),
      3: t('Ср'),
      4: t('Чт'),
      5: t('Пт'),
      6: t('Сб'),
    }),
    [i18n.language]
  );

  const localeHeader = useMemo(() => {
    return {
      code: 'custom',
      formatDistance: () => '',
      formatRelative: () => '',
      localize: {
        ordinalNumber: (n: number) => `${n}`,
        era: (n: number) => `${n}`,
        quarter: (n: number) => `${n}`,
        month: (n: number) => months[n],
        day: (n: number) => days[n],
        dayPeriod: (n: number) => `${n}`,
      },
      formatLong: {
        date: () => dateFormat,
        time: () => dateFormat,
        dateTime: () => dateFormat,
      },
      match: {
        ordinalNumber: () => ({ value: '', rest: '' }),
        era: () => ({ value: '', rest: '' }),
        quarter: () => ({ value: '', rest: '' }),
        month: () => ({ value: '', rest: '' }),
        day: () => ({ value: '', rest: '' }),
        dayPeriod: () => ({ value: '', rest: '' }),
      },
      options: {
        weekStartsOn: 1 as 0 | 1 | 2 | 3 | 4 | 5 | 6,
        firstWeekContainsDate: 1 as 1 | 2 | 3 | 4 | 5 | 6 | 7,
      },
    };
  }, [i18n.language, days, months]);

  return (
    <Box className={cn(css.DatePickerRoot, className)} data-testid="DatePicker">
      <ReactDatePicker
        {...reactDatePickerProps}
        ariaInvalid={error ? 'true' : ariaInvalid}
        customInput={
          <DateInput
            data-testid="DatePickerInput"
            disabled={disabled}
            error={error}
            fullWidth={fullWidth}
            helperText={helperText}
            label={label}
            required={required}
          />
        }
        dateFormat={dateFormat}
        disabled={disabled}
        locale={localeHeader}
        placeholderText={placeholderText}
        popperPlacement={fullWidth ? 'bottom-end' : undefined}
        required={required}
        scrollableYearDropdown={scrollableYearDropdown}
        showMonthDropdown={showMonthDropdown}
        showPopperArrow={showPopperArrow}
        showYearDropdown={showYearDropdown}
        title={label || title}
        onChange={onChange}
      />
    </Box>
  );
});
