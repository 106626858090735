import React from 'react';

import ReactDOM from 'react-dom';

import './index.scss';
import '@ibox/ui/src/global.scss';

import { App } from './App';
import './i18n';

ReactDOM.render(<App />, document.getElementById('root'));
