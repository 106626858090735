import React from 'react';

import fp from 'lodash/fp';
import cn from 'classnames';

import * as IBoxIcons from './svg';

import css from './index.module.scss';

export * as Icons from './svg';

export interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  name: keyof typeof IBoxIcons;
  className?: string;
  size?: 'Large' | 'Medium' | 'Small' | 'Inherit';
}

const LocalIcons: Record<IconProps['name'], unknown> = fp.pipe(
  fp.toPairs,
  fp.reduce(
    (
      iconDictionary: Record<IconProps['name'], unknown>,
      [iconName, IconComponent]: [IconProps['name'], unknown]
    ) => {
      iconDictionary[iconName] = <IconComponent />;

      return iconDictionary;
    },
    {} as Record<IconProps['name'], unknown>
  )
)(IBoxIcons);
export const Icon = (props: IconProps) => {
  const { name, className, size = 'Medium', ...otherProps } = props;

  return (
    <div
      className={cn(className, css.iconContainer, css[size])}
      {...otherProps}
    >
      {LocalIcons[name]}
    </div>
  );
};
