import { createAction } from '../../../helpers/redux';
import { types } from '../../types';

export const set = createAction(types.SET_REPORTS);
export const getList = createAction(types.REPORT_GET_LIST);
export const setList = createAction(types.REPORT_SET_LIST);
export const clear = createAction(types.CLEAR_REPORTS);
export const addHistoryFilters = createAction(
  types.ADD_REPORTS_HISTORY_FILTERS
);
export const addSavedFilters = createAction(types.ADD_REPORTS_SAVED_FILTERS);
