import React, { useCallback, useEffect } from 'react';

import cn from 'classnames';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import styles from './toggle-buttons.module.scss';

export type ToggleButtonValueType = number | string;

export type ToggleButtonValue<T extends ToggleButtonValueType = string> = {
  label: React.ReactNode;
  value: T;
};

export type ToggleButtonsProps<T extends ToggleButtonValueType = string> = {
  onChange?: (value: T) => void;
  items: ToggleButtonValue<T>[];
  defaultValue?: T;
  className?: string;
};

export const ToggleButtons = <T extends ToggleButtonValueType = string>({
  onChange,
  items,
  className,
  defaultValue = items[0]?.value,
}: ToggleButtonsProps<T>) => {
  const [currentValue, setCurrentValue] = React.useState<T | undefined>(
    defaultValue
  );

  useEffect(() => {
    if (defaultValue) {
      setCurrentValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = useCallback(
    (_e: React.MouseEvent<HTMLElement>, value: T) => {
      setCurrentValue(value);
      onChange?.(value);
    },
    [onChange]
  );

  return (
    <ToggleButtonGroup
      exclusive
      className={cn(styles.container, className)}
      data-testid="ToggleButtons"
      value={currentValue}
      onChange={handleChange}
    >
      {items.map(({ label, value }) => (
        <ToggleButton
          disabled={value === currentValue}
          key={value}
          value={value}
        >
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
