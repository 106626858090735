import React, { useRef, useState } from 'react';

import cn from 'classnames';
import { Box, ButtonBase, ButtonBaseProps, Popover } from '@mui/material';

import { useEvent } from '../../../utils/react.hooks';

import { DropDownMarker } from './drop-down-marker';

import styles from './common-styles.module.scss';

interface MenuItemProps extends Omit<ButtonBaseProps, 'children'> {
  label: string;
  icon?: React.ReactNode;
  selected?: boolean;
  dropdown?: boolean;
  children?: (closePopup: () => void) => React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
}

export function MainMenuItem({
  label,
  icon,
  selected,
  dropdown,
  children,
  onClick,
  onClose,
  onOpen,
  ...buttonProps
}: MenuItemProps) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleMenuItemClick = useEvent(() => {
    const isOpen = !open;
    setOpen(isOpen);
    if (isOpen) {
      onOpen && onOpen();
    }
  });

  const handleClose = useEvent(() => {
    setOpen(false);
    onClose && onClose();
  });

  return (
    <>
      <ButtonBase
        {...buttonProps}
        className={cn(styles.AppMainMenuItem, {
          [styles.AppMainMenuItemOpen]: open,
          [styles.AppMainMenuItemSelected]: selected,
        })}
        ref={anchorRef}
        onClick={dropdown ? handleMenuItemClick : onClick}
      >
        {icon ? <Box className={styles.AppMainMenuItemIcon}>{icon}</Box> : null}
        <Box className={styles.AppMainMenuItemLabel}>
          {label}
          {children && dropdown ? <DropDownMarker open={open} /> : null}
        </Box>
      </ButtonBase>
      {!dropdown ? null : (
        <Popover
          anchorEl={anchorRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          className={styles.AppMainMenuItemDropdown}
          open={open}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handleClose}
        >
          {children ? children(handleClose) : null}
        </Popover>
      )}
    </>
  );
}

MainMenuItem.defaultProps = {
  dropdown: false,
};
