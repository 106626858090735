// eslint-disable-next-line @typescript-eslint/no-empty-function
export const constVoid = () => {};

export const isPhone = (data: string): boolean =>
  /^((8|\+7)[-]?)?(\(?\d{3}\)?[-]?)?[\d-]{10,10}$/.test(data);

export const isIsoDate = (str?: string): boolean => {
  if (!str || !/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) {
    return false;
  }
  const d = new Date(str);
  return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str; // valid date
};
