import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import css from './index.module.scss';

type LinkCellProps = {
  link: string;
  text: string;
};

export const LinkCell: FC<LinkCellProps> = ({ link, text, ...props }) => (
  <Link data-testid="link" {...props} className={css['link-cell']} to={link}>
    {text}
  </Link>
);
