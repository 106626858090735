import React from 'react';

import { default as cn } from 'classnames';
import { Box, CircularProgress } from '@mui/material';

import css from './spinner.module.scss';

export type SpinnerProps = {
  className?: string;
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'inherit';
};

export const Spinner = ({ color, className }: SpinnerProps) => (
  <Box className={cn(css.container, className)}>
    <CircularProgress color={color} />
  </Box>
);
